<template>
  <div :data-test="dataTestValue" class="w-full" v-if="!edit">
    <Tooltip :content="value" :visible="visibility">
      <span
        :class="[
          'bg-tmrw-white text-tnrw-blue py-1.5 leading-5 border-b border-solid border-tmrw-blue truncate w-full block h-8',
          alignToLeft,
          fieldClasses,
        ]"
        @mouseover="showToolTip"
        @mouseleave="hideToolTip"
      >{{replaceStringFilter(value === specimenTypeEggs ? specimenTypeEgg : value)}}<slot />
      </span>
    </Tooltip>
  </div>
  <div class="data-field w-full border-b border-solid border-tmrw-blue py-1.5 leading-5" v-else>
    <input
      :data-test="dataTestValue"
      class="w-full outline-none"
      :type="fieldType"
      :value="inputValue"
      :maxlength="maxlength || 1000000"
      :tabindex="tabKey"
      :placeholder="placeholder"
      @input="handleChange"
      @blur="handleBlur($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  SPECIMEN_TYPE_EGGS,
  SPECIMEN_TYPE_EGG
} from '@/constants'
import { replaceStringFilter } from '@/filters'
import Tooltip from '../TooltipComponent/Tooltip.vue'

export default {
  name: 'data-field',
  data() {
    return {
      specimenTypeEggs: SPECIMEN_TYPE_EGGS,
      specimenTypeEgg: SPECIMEN_TYPE_EGG,
      inputValue: this.value,
      visibility: false
    }
  },
  emits: ['changeValue'],
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    tabKey: {
      type: Number,
      required: false,
      default: 0
    },
    edit: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    maxlength: {
      type: Number,
      required: false
    },
    maxValue: {
      type: Number,
      required: false
    },
    fieldType: {
      type: String,
      default: 'text',
      required: false
    },
    alignContentToLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    fieldClasses: {
      type: String,
      required: false,
      default: ''
    },
    block: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: Boolean,
      required: false,
      default: false
    },
    dataTest: {
      type: String,
      required: true
    }
  },
  methods: {
    replaceStringFilter,
    handleChange(event) {
      // Validate if the field is number or text
      this.inputValue = this.fieldType === 'number' ? event.target.value.substr(0, this.maxlength) : event.target.value
      this.$emit('changeValue', this.inputValue)
      this.$forceUpdate()
    },
    showToolTip() {
      this.visibility = this.tooltip && this.$el.children[0].offsetWidth < this.$el.children[0].scrollWidth
    },
    hideToolTip() {
      this.visibility = false
    },
    handleBlur(event) {
      const { redirectedFrom, name: viewName, currentRoute } = this.$router.currentRoute.value
      const { hostname } = window.location
      this.$mixpanel.track(`Data Field Event at ${viewName}`, {
        component: 'Data Field',
        'current-route': currentRoute,
        'view-name': viewName,
        'redirected-from': redirectedFrom,
        'field-value': event.target.value,
        'clinic-name': this.appBaseConfig.clinicName,
        'site-name': this.appBaseConfig.siteName,
        'user-name': this.loggedUserInfo.loginName,
        'user-type': this.loggedUserInfo.userType,
        hostname
      })
    }
  },
  computed: {
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    alignToLeft() {
      if (this.alignContentToLeft) {
        return 'data-field__align-left'
      }
      return null
    },
    dataTestValue() {
      if (this.dataTest) {
        return this.dataTest.replaceAll(' ', '-').toLowerCase()
      }
      return ''
    }
  },
  components: { Tooltip }
}
</script>
<style lang="scss" scoped>
input:focus {
  @apply bg-tmrw-gray-light;
}
</style>
