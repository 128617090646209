<template>
  <MainContainer data-test="scan-beacon" :gridSlots="isDonationToPatientTicket ? '3' : '4'">
    <top-header @backStep="handleClickBack">
      <p v-if="isReplaceCryoBeacon">Replace {{ CryoBeacon }}</p>
      <HeaderInfo v-else />
    </top-header>
    <ActionBar data-test="scan-beacon__action-bar" colsDistribution="8/4">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">
          Please register the {{ CryoBeacon }} by placing it in the Registration Tray
        </dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="isReplaceCryoBeacon"
          btnType="button"
          :isLoading="isLoading"
          data-test="scan-beacon__replace-cryo-beacon-button"
          btnText="Update Ticket"
          btnStyle="primary"
          :is-disabled="!beaconBarcodeNumber"
          @click="handleReplaceCryoBeacon"
        />
        <!-- Edit Donation to Patient ticket -->
        <!-- We did not remove this flow from the mixin since it is about editing -->
        <button-dynamic
          v-else-if="isDonationToPatientTicket()"
          btnType="button"
          :isLoading="isLoading"
          data-test="scan-beacon__update-ticket-button"
          btnText="Update Ticket"
          btnStyle="primary"
          :is-disabled="!beaconBarcodeNumber"
          @click="handleDonateTicketUpdate"
        />
        <!-- Submit ticket Update -->
        <!-- We did not remove this flow from the mixin since it is about editing -->
        <button-dynamic
          v-else-if="isEditingTicket() && isScanningLastBeacon"
          btnType="button"
          :isLoading="isLoading"
          data-test="scan-beacon__update-ticket-button"
          btnText="Update Ticket"
          btnStyle="primary"
          :is-disabled="!beaconBarcodeNumber"
          @click="ticketUpdateHandle"
        />
        <!-- Next to final ticket Preview -->
        <button-dynamic
          v-else-if="isScanningLastBeacon"
          data-test="scan-beacon__next-button"
          btnType="button"
          btnText="Next"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="arrow-circle-right"
          :isDisabled="!isBeaconValid"
          @click="handleBeaconsComplete"
        />
        <!-- Go to Next Beacon -->
        <button-dynamic
          v-else
          data-test="scan-beacon__next-button"
          btnType="button"
          :btnText="`Next ${CryoBeacon}`"
          btnStyle="primary"
          :isDisabled="!isBeaconValid"
          @click="onClickNextBeacon"
        />
      </template>
    </ActionBar>
    <!-- <BreadCrumbs class="py-2" :items="breadCrumbs" v-if="!isDonationToPatientTicket" /> -->
    <BreadCrumbs :items="getActiveBreadcrumbs" v-if="!isDonationToPatientTicket" />
    <new-cryoBeacon @isValidBeaconHandler="isValidBeaconHandler" isReplaceCryoBeacon />
  </MainContainer>
  <ModalGeneric
    v-if="isReplaceCryoBeaconModalOpen"
    data-test="replace-cryobeacon-modal"
    :title="`Replace ${CryoBeacon}`"
    :message="`Once the new ${CryoBeacon} is submerged in liquid nitrogen, please move the contents of ${CryoBeacon} ${oldCryoBeacon} to ${CryoBeacon} ${newCryoBeacon}.`"
    confirm-label="Ok"
    modal-width="w-5/12"
    :title-classes="['text-2xl']"
    :message-classes="['text-lg pt-4']"
    confirmButtonStyle="secondary"
    hideCancel
    @confirm="handleCryoBeaconReplace"
    @cancel="handleCryoBeaconReplaceCancel"
  />
  <loading-ui
    v-if="isProcessingCryoBeaconReplace"
    modal
    :message="`Updating Ticket ${CryoBeacon}`"
  />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ModalGeneric from '@/components/ModalGeneric/ModalGeneric.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import NewCryoBeacon from '@/components/NewCryoBeacon/NewCryoBeacon.vue'
import {
  CRYOBEACON,
  EXISTING_BEACON_TICKET_SUCCESS_MESSAGE,
  ROBOT_TRANSFER_IN,
  EXISTING_BEACON_STEP,
  METHOD_FREEZE,
  METHOD_IMPORT,
  PROCEDURE_TYPE_DONATION_TO_PATIENT
} from '@/constants'
import toast from '@/config/toast'
import * as ss from '@/config/session-storage-help'
import { useTicket } from '@/composables/useTicket'
import store from '@/store'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { getBreadcrumbs, getScreeningStatusBeacons } from '@/helpers/newTicketHelpers'
import { processNextBeacon } from '@/helpers/manageBeacon'
import { updateDonationToPatientBeaconBarcode, handleTicketUpdate } from '@/helpers/updateTicket'
import { STEP_LABEL_SCAN } from '@/constants/ticketSteps'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'

export default {
  name: 'scan-beacon',
  setup() {
    const { ticket, beacons, updateTicketBeacons, saveTicket, isEditingTicket } = useTicket()
    return {
      ticket,
      beacons,
      updateTicketBeacons,
      saveTicket,
      isEditingTicket
    }
  },
  data() {
    return {
      fromRouteName: null,
      isPrintBeaconLabelModalVisible: false,
      CryoBeacon: CRYOBEACON,
      isLoading: false,
      isBeaconValid: false,
      isReplaceCryoBeacon: false,
      selectedTicket: null,
      oldCryoBeacon: '',
      newCryoBeacon: '',
      isReplaceCryoBeaconModalOpen: false,
      isProcessingCryoBeaconReplace: false
    }
  },
  created() {
    this.setRobotTransfer(ROBOT_TRANSFER_IN)
  },
  beforeRouteEnter(to, from, next) {
    // we need to account for multiple screens that route to ScanBeacon, in
    // order to be able to route back (eg.: click back button)
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called
    next((vm) => {
      // access to component instance via `vm`
      // eslint-disable-next-line no-param-reassign
      vm.fromRouteName = from.name
    })
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== STEP_LABEL_SCAN) {
      store.commit('beaconsModule/clearScannedBeaconBarcodeNumbers')
    }
    next()
  },
  mounted() {
    this.isReplaceCryoBeacon = this.$route.query.isReplaceCryoBeacon
    this.beacons = ss.getFieldSessionStorage('newTicket', 'beacons')
  },
  computed: {
    ...mapGetters('beaconsModule', ['beaconBarcodeNumber', 'scannedBeaconBarcodes']),
    ...mapGetters('authModule', ['loggedUserInfo', 'appBaseConfig']),
    ...mapGetters('newTicketModule', ['currentTicketStep', 'selectedMethod', 'selectedPatient']),
    ...mapGetters('ticketsModule', ['selectedDestination']),
    ...mapGetters('selectedTicketsModule', ['selectedTickets']),
    getActiveBreadcrumbs() {
      const currentBreadcrumbs = getBreadcrumbs({
        currentTicketStep: this.currentTicketStep,
        selectedMethod: this.selectedMethod
      })
      return currentBreadcrumbs
    },
    isScanningLastBeacon() {
      const unScannedBeacons = this.beacons.filter((beacon) => !beacon.done)
      return unScannedBeacons.length === 1
    }
  },
  methods: {
    ...mapActions('beaconsModule', [
      'checkBeaconId',
      'setBeaconIdCheckMessage',
      'setSingleBeaconReaderReads',
      'setBeaconBarcode'
    ]),
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    ...mapActions('selectedTicketsModule', ['updateTickets']),
    ...mapActions('ticketsModule', ['updateTicketCryoBeacon']),
    ...mapMutations('beaconsModule', ['addScannedBeaconBarcodeNumber']),
    ...mapMutations('ticketModule', ['setBeacons', 'setRobotTransfer']),
    async ticketUpdateHandle() {
      this.isLoading = true
      this.displaySpinner('Updating Ticket')

      const unitId = this.selectedDestination
      if (unitId) {
        this.ticket = { ...this.ticket, unitId }
      }

      try {
        await handleTicketUpdate({
          router: this.$router,
          ticket: this.ticket,
          collectionProtocolId: this.appBaseConfig.collectionProtocolId,
          beaconBarcodeNumber: this.beaconBarcodeNumber,
          loggedUserId: this.loggedUserInfo.userId
        })
      } catch (err) {
        toast.error({ title: err.message })
      } finally {
        this.isLoading = false
        ss.removeFieldSessionStorage('selectedDestination')
        this.hideSpinner()
      }
    },
    handleCryoBeaconReplaceCancel() {
      this.isReplaceCryoBeaconModalOpen = false
    },
    async handleDonateTicketUpdate() {
      this.isLoading = true
      const [donateTicketData] = this.selectedTickets
      const donationToPatientTicket = updateDonationToPatientBeaconBarcode(
        donateTicketData,
        this.beaconBarcodeNumber
      )
      const { ticketId } = donationToPatientTicket
      try {
        await this.updateTickets({ tickets: [donationToPatientTicket], ticketId })
        this.setBeaconBarcode(null)
        this.$router.push('/selected-tickets')
        toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
        this.isLoading = false
      } catch (err) {
        toast.error({ title: err.message })
      } finally {
        this.isLoading = false
      }
    },
    async onClickNextBeacon() {
      this.setSingleBeaconReaderReads(this.beaconBarcodeNumber)
      // TODO: check what is this used for in the ticket creation flow, should it be part of the ticketModule?
      this.addScannedBeaconBarcodeNumber(this.beaconBarcodeNumber)
      // TODO: check what is this used for in the ticket creation flow, should it be part of the ticketModule?
      ss.setFieldSessionStorage('lastScannedBeacon', this.beaconBarcodeNumber)
      this.beacons = processNextBeacon(this.beacons)
      this.ticket = this.updateTicketBeacons(this.ticket, this.beacons)
      this.saveTicket(this.ticket)

      this.setBeacons(this.beacons)

      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        beaconBarcodeNumber: this.beaconBarcodeNumber,
        beacons: this.beacons,
        hasNextBeacon: true
      })
      this.$router.push({ name: nextStepPath })
    },
    isValidBeaconHandler(isBeaconValid) {
      this.isBeaconValid = isBeaconValid
    },
    async handleBeaconsComplete() {
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        beaconBarcodeNumber: this.beaconBarcodeNumber
      })

      this.$router.push({ name: nextStepPath })
    },
    async handleClickBack() {
      if (this.isReplaceCryoBeacon) {
        const isRelocationTicket = ss.getFieldSessionStorage('isRelocationTicket')
        ss.removeFieldSessionStorage('isRelocationTicket')
        this.$router.push({
          path: isRelocationTicket ? '/biorepository/relocation-ticket' : '/selected-tickets'
        })
        return
      }
      let isExistingBeacon = false
      if ([METHOD_FREEZE, METHOD_IMPORT].includes(this.selectedMethod)) {
        this.displaySpinner('Validating Patient Inventory')
        try {
          const beaconResponse = await getScreeningStatusBeacons({
            beacons: this.beacons,
            patientId: this.selectedPatient[0].globalPatientNumber
          })

          this.hideSpinner()
          isExistingBeacon = beaconResponse === EXISTING_BEACON_STEP
        } catch (error) {
          this.hideSpinner()
        }
      }
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        isExistingBeacon
      })
      this.$router.replace({ name: previousStepPath })
    },
    isDonationToPatientTicket() {
      const selectedTickets = this.selectedTickets
      if (!selectedTickets.length) {
        return false
      }
      const [{ procedureType }] = selectedTickets
      return procedureType === PROCEDURE_TYPE_DONATION_TO_PATIENT
    },
    handleReplaceCryoBeacon() {
      this.selectedTicket = this.selectedTickets[0]
      this.oldCryoBeacon = this.selectedTicket.beaconBarcode
      this.newCryoBeacon = this.beaconBarcodeNumber
      this.isReplaceCryoBeaconModalOpen = true
    },
    async handleCryoBeaconReplace() {
      this.isReplaceCryoBeaconModalOpen = false
      this.isProcessingCryoBeaconReplace = true
      const { ticketId } = this.selectedTicket
      try {
        await this.updateTicketCryoBeacon({ ticketId, beaconId: this.beaconBarcodeNumber })
        toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
        const isRelocationTicket = ss.getFieldSessionStorage('isRelocationTicket')
        ss.removeFieldSessionStorage('isRelocationTicket')
        this.$router.push(
          isRelocationTicket === 'true' ? '/biorepository/relocation-ticket' : '/selected-tickets'
        )
      } catch (err) {
        toast.error({ title: err.message })
      } finally {
        this.isProcessingCryoBeaconReplace = false
      }
    }
  },
  components: {
    TopHeader,
    HeaderInfo,
    MainContainer,
    DynamicTitle,
    ActionBar,
    BreadCrumbs,
    ButtonDynamic,
    NewCryoBeacon,
    ModalGeneric,
    LoadingUi
  }
}
</script>

<style lang="scss">
.single-beacon-reader-img {
  max-height: 500px;
}
</style>
