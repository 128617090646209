<template>
  <div data-test="export-shipping-options" class="flex mt-12">
    <div class="flex flex-col justify-between w-72 mr-4">
      <button-dynamic
        btnType="button"
        btnStyle="secondary"
        :btnText="`${shippingSourceData ? shippingSourceData.name : 'Select Destination'}`"
        showIcon
        :fontAwesomeIconClass="shippingSourceData ? 'edit' : 'plus'"
        iconPosition="right"
        class="w-full py-4"
        :class="[{
          'rounded-bl-none rounded-br-none border-none': !shippingSourceData,
        }]"
        @click="openClinicSelector"
      />
      <div class="bg-white border-t border-tmrw-error text-tmrw-error text-xs pl-2" v-if="!shippingSourceData">
        Required
      </div>
    </div>
    <div v-for="(item, index) in items" :key="index">
      <div
        :data-test="`reason-shipping-${item.name}`"
        class="text-xl text-white leading-6 w-72 bg-tmrw-blue-light mr-4 rounded-lg"
        :class="[{
          'rounded-bl-none rounded-br-none': !item.value && item.required,
        }]"
      >
        <input
          :data-test="`reason-shipping-${item.name}-input`"
          class="mr-1 w-full p-4 text-tmrw-blue-light rounded-lg"
          :class="[{
            'rounded-bl-none rounded-br-none': !item.value && item.required,
          }]"
          :placeholder="item.placeholder"
          :name="item.name"
          v-model="item.value"
          @input="syncData($event)"
        />
        <div v-if="item.required">
          <div class="bg-white border-t border-tmrw-error text-tmrw-error text-xs pl-2" v-if="inputFieldIsEmpty(item.value)">
            Required
          </div>
        </div>
      </div>
    </div>
    <ExternalClinicsSelector
      v-if="isClinicSelectorOpen"
      :isShippingSource="false"
      @selectedShippingSource="updateShippingSource"
      @closeClinicSelector="closeClinicSelector"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  SHIPPING_ID,
  CARRIER_ID,
  NOTES
} from '@/constants'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import ExternalClinicsSelector from '@/components/ExternalClinicsSelector/ExternalClinicsSelector.vue'

export default {
  name: 'shipping',
  data() {
    return {
      destination: '',
      externalClinics: [],
      error: false,
      isClinicSelectorOpen: false,
      shippingSourceData: false,
      items: [
        {
          placeholder: SHIPPING_ID,
          name: 'shippingId',
          value: '',
          required: true
        },
        {
          placeholder: CARRIER_ID,
          name: 'carrierId',
          value: '',
          required: true
        },
        {
          placeholder: NOTES,
          name: 'notes',
          value: '',
          required: false
        }
      ]
    }
  },
  async created() {
    try {
      this.externalClinics = await this.getExternalClinics()
    } catch {
      this.externalClinics = []
    }
  },
  emits: ['dataSync'],
  methods: {
    ...mapActions('patientsModule', ['getExternalClinics']),
    syncData(event) {
      this.$emit('dataSync', { [event.target.name]: event.target.value })
    },
    inputFieldIsEmpty(value) {
      return value === ''
    },
    openClinicSelector() {
      this.isClinicSelectorOpen = true
    },
    closeClinicSelector() {
      this.isClinicSelectorOpen = false
    },
    updateShippingSource(shippingSourceData) {
      this.shippingSourceData = shippingSourceData
      this.$emit('dataSync', { destination: shippingSourceData.id })
    }
  },
  computed: {
    destinationFieldIsEmpty() {
      return !this.destination
    }
  },
  components: {
    ButtonDynamic,
    ExternalClinicsSelector
  }
}
</script>
<style lang="scss" scoped>
  input::-webkit-input-placeholder {
    @apply text-tmrw-blue-light;
  }
</style>
