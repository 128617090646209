<template>
  <loading-ui v-if="isLoading" modal message="Loading Tickets" />
  <MainContainer v-if="!isLoading" data-test="final-ticket-view" gridSlots="3">
    <top-header @backStep="handleClickBack" :isDisabled="ticketHasBeenCreated">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="final-ticket-view__action-bar" colsDistribution="9/3">
      <template v-slot:left-side>
        <dynamic-title titleType="h3-bold" floatTo="left">{{ pageTitle }}</dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="hasTicketIds"
          btnText="Print"
          btnType="button"
          btnStyle="secondary"
          data-test="final-ticket__print-button"
          showIcon
          fontAwesomeIconClass="print"
          addMarginRight
          @click="showPrinterModal"
        />
        <button-dynamic
          btnDataTest="final-ticket__return-to-flightboard-button"
          v-if="hasTicketIds"
          btnText="Return to Flight Board"
          btnType="button"
          btnStyle="primary"
          @click="returnToFligthBoard"
        />
        <button-dynamic
          btnDataTest="final-ticket__create-ticket-button"
          v-else
          btnText="Create Ticket"
          btnType="button"
          btnStyle="primary"
          @click="createTicket"
        />
      </template>
    </ActionBar>
    <div class="final-ticket">
      <BreadCrumbs v-if="isExport" :items="breadcrumbsItems" />
      <BreadCrumbs v-if="isFreeze || isImport" :items="getActiveBreadcrumbs" />
      <div
        v-if="isPrinterModalVisible"
        data-testid="final-ticket__printer-modal"
        class="h-screen w-screen flex justify-center items-center z-50 fixed t-0 l-0 bg-black bg-opacity-50 top-0 left-0"
      >
        <div class="relative bg-tmrw-blue p-4 rounded-lg w-100">
          <span
            class="absolute flex justify-center items-center top-0 right-0 bg-white rounded-full p-2 w-4 h-4 mt-5 mr-5 cursor-pointer"
          >
            <i @click="closePrinterModal" class="fas fa-times text-sm text-blue-600" />
          </span>
          <h3 class="font-bold text-white text-2xl mb-4">Select Printer</h3>
          <p>
            <span class="text-white">Default printer: </span>&nbsp;
            <select v-model="selectedPrinter">
              <option class="text-black" value="0">Print PDF</option>
              <option
                class="text-black"
                v-for="(printer, index) in activePrinters"
                :key="index"
                :value="printer.uid"
              >
                {{ getPrinterName(printer.name) }}
              </option>
            </select>
          </p>
          <button-dynamic
            btnText="Print"
            btnType="button"
            btnStyle="primary"
            floatTo="right"
            showIcon
            fontAwesomeIconClass="print"
            add-margin-top
            @click="sendPrintJob"
          />
        </div>
      </div>
      <div
        data-testid="final-ticket__ticket-card-container"
        class="overflow-auto box-border relative mb-4"
        v-if="ticketsToDisplay.length"
      >
        <div
          v-if="hasCryoRobotSelector"
          data-test="cryorobot-destination-selector-wrapper"
          class="mb-20"
        >
          <div class="flex w-full bg-white rounded-md py-5 px-4 mb-5 items-center">
            <!-- Dropdown will always show for batch Store, even if there is only one or none option to chose from -->
            <CustomSelect
              class="w-72"
              data-testid="batch-ticket__destination"
              dataTest="batch-ticket__destination"
              :options="destinationOptions"
              hasLargePadding
              noTemporaryContainers
              noNonTmrwLocation
              hasFontInconsolata
              :default-option-label="defaultOptionLabel"
              has-large-font
              @update:modelValue="handleSelectDestination"
              isDefaultOptionDisabled="true"
              :modelValue="defaultDestinationValue"
              :show-error="noDestinationSelected"
            />
          <p v-if="noDestinationSelected" class="text-tmrw-error font-inconsolata text-2xl mx-16"> <i class="fa fa-exclamation-triangle" /> {{ chooseCryorobotLabel }}</p>
          </div>
        <dynamic-title titleType="h3-bold" floatTo="left">{{
          pageSubtitle
        }}</dynamic-title>
        </div>
        <paginator
          :total-items-to-display="ticketsToDisplay.length"
          :max-paginators-count-per-view="maxPaginatorsCountPerView"
          :currentActivePage="currentActivePage"
          @handlePageChange="onChangeTicketToView"
          data-test="final-ticket__paginator"
        />
        <TicketToFinish
          v-for="(ticket, key) in getItemsToPrintPerPage"
          :ticket="ticket"
          :ticketId="currentTicketId"
          :key="`ticket-to-finish-${key}`"
          :newTicketData="newTicketData"
          :selectedTicketIndex="selectedTicketIndex"
        />
        <div class="mt-10" v-if="showRemainingSpecimens">
          <dynamic-title addMarginBottom titleType="h3" floatTo="left">
            Remaining in CryoBeacon
          </dynamic-title>
          <div class="flex w-full bg-white rounded-md py-5 px-10 mt-5">
            <span class="w-full">
              <InventorySpecimenTables
                data-test="ticket-detail__remaining-specimens"
                class="w-full"
                :ticketDetail="ticketDetailModel"
                :eggSpecimenHeaders="eggHeaders"
                :eggSpecimens="eggSpecimens"
                :embryoSpecimenHeaders="embryoHeaders"
                :embryoSpecimens="embryoSpecimens"
              />
            </span>
          </div>
        </div>
        <CustomizableModal
          v-if="isCustomModalOpen"
          :title="customizableModalTitle"
          :message="customizableModalMsg"
          @hideModal="hideCustomizableModal"
          :buttonsData="customizableModalButtonsData"
        />
      </div>
    </div>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { computed } from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import toast from '@/config/toast'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import TicketToFinish from '@/components/TicketToFinish/TicketToFinish.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import CustomizableModal from '@/components/CustomazibleModal/CustomazibleModal.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import InventorySpecimenTables from '@/views/InventorySpecimenTables/InventorySpecimenTables.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import Paginator from '@/components/Paginator/Paginator.vue'
import { printTickets, getInstalledPrinters, setPrinterName } from '@/modules/printer'
import { selectEmbryoTypeLabel, sortSpecimensByCryodeviceId } from '@/utils'
import {
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE,
  SPECIMEN_TYPE_EGG,
  SPECIMEN_TYPE_EGGS,
  SINGLE_IMPORT_CONST,
  ZEBRA_TICKET_PRINTERS_ID,
  METHOD_FREEZE_LOWERCASE,
  METHOD_IMPORT,
  METHOD_EXPORT,
  METHOD_EXPORT_UPPER,
  PROCEDURE_TYPE_SINGLE_IMPORT,
  PROCEDURE_TYPE_BATCH_UPPERCASE,
  DONATE_TO_PATIENT_VALUE,
  MAX_PAGINATORS_COUNT_PER_VIEW,
  METHOD_FREEZE,
  CRYOROBOT,
  ROBOT_TRANSFER_IN,
  METHOD_THAW
} from '@/constants'
import * as ss from '@/config/session-storage-help'
import { removeKeysWithNullValues } from '@/helpers/removeKeysWithNullValues'
import { getTicketApi } from '@/services/tickets'
import { fetchPatientsById } from '@/services/patients'
import { selectedTicketToPdf } from '@/helpers/printPDF/helpers'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { getBreadcrumbs } from '@/helpers/newTicketHelpers'
import { isFeatureEnabled, MULTIPLE_PATIENTS_ENABLED_FF } from '@/helpers/featureFlags'
import { useGetDestination, useValidateDestination } from '@/composables'
import { dynamicEgg, dynamicEmbryo } from '@/constants/table-headers/dynamic-headers'

dayjs.extend(utc)

export default {
  name: 'final-ticket',
  components: {
    TopHeader,
    HeaderInfo,
    TicketToFinish,
    ButtonDynamic,
    BreadCrumbs,
    MainContainer,
    DynamicTitle,
    ActionBar,
    CustomizableModal,
    Paginator,
    LoadingUi,
    CustomSelect,
    InventorySpecimenTables
  },
  setup() {
    const {
      destinationOptions,
      onSelectDestination,
      selectedDestination: selectedDestinationBatch,
      resetDestination,
      getDestinationOptions
    } = useGetDestination()
    const { validateUnitSpace, setUnitSpaceValidationFailed, unitSpaceValidationFailed } = useValidateDestination()
    const hasMultipleUnits = computed(() => destinationOptions.value?.length > 1)

    return {
      destinationOptions,
      onSelectDestination,
      selectedDestinationBatch,
      validateUnitSpace,
      setUnitSpaceValidationFailed,
      unitSpaceValidationFailed,
      hasMultipleUnits,
      resetDestination,
      getDestinationOptions
    }
  },
  data() {
    return {
      isLoading: true,
      noDestinationSelected: false,
      fromRouteName: null,
      newTicket: {},
      newTicketsData: [],
      ticketsToDisplay: [],
      ticketsToPost: [],
      ticketIds: [],
      sharedPatientIds: [],
      activePrinters: [],
      selectedPrinter: '0',
      isPrinterModalVisible: false,
      dynamicPageTitle: '',
      isCustomModalOpen: false,
      customizableModalTitle: 'Please Retry',
      chooseCryorobotLabel: `Choose a ${CRYOROBOT} in order to continue`,
      customizableModalMsg: `Unfortunately there isn’t enough space left in the single ${CRYOROBOT} for all of the selected Tickets. You can go back and select less, or return to the FlightBoard and complete the Tickets individually.`,
      customizableModalButtonsData: [
        {
          btnText: 'Return to Flight Board',
          btnType: 'button',
          btnStyle: 'primary',
          action: this.returnToFligthBoard
        },
        {
          btnText: 'Go back',
          btnType: 'button',
          btnStyle: 'secondary',
          classes: [
            'focus:outline-none bg-white text-tmrw-blue hover:text-black mx-4 rounded-lg cursor-pointer px-4 py-2 text-lg flex justify-center items-center'
          ],
          action: this.handleClickBack
        }
      ],
      currentActivePage: 1,
      maxPaginatorsCountPerView: MAX_PAGINATORS_COUNT_PER_VIEW,
      ticketHasBeenCreated: false,
      availableDestinationOptions: [],
      ticketDetailModel: {
        sort: {
          orderBy: 'cryodeviceBarcode',
          direction: 'asc'
        },
        options: []
      }
    }
  },
  async created() {
    try {
      this.dynamicPageTitle = 'Please confirm that everything is correct and press Create Ticket'
      let patientIds = []

      await this.applyCryoDateToCryoDevice()
      await this.fetchEmbryoTypes()
      this.externalClinics = await this.getExternalClinics()

      if (this.patients?.length) {
        patientIds = this.patients.map((patient) => patient.globalPatientNumber)
        patientIds = patientIds.filter((id) => id !== this.primaryPatientId)
        this.sharedPatientIds = patientIds
      }
      this.ticketsToDisplay = await this.buildTicketsToPost()
    } catch (err) {
      toast.error({ title: err.message })
    } finally {
      this.isLoading = false
    }
  },
  async mounted() {
    if (this.isBatchTicket) {
      this.getDestinationOptions({ showDefault: false })
      this.resetDestination()
    }
    // Browser Print Setup
    const localPrinters = await getInstalledPrinters()
    localPrinters.printer?.forEach((printer) => {
      ZEBRA_TICKET_PRINTERS_ID.forEach((printerId) => {
        if (printer.name.includes(printerId)) {
          this.activePrinters.push(printer)
          this.isPrinterActive = true
          this.selectedPrinter = printer.uid
        }
      })
    })
  },
  beforeUnmount() {
    ss.removeFieldSessionStorage('newBeaconsArray')
    ss.removeFieldSessionStorage('lastScannedBeacon')
    this.setBeaconBarcode(null)
  },
  beforeRouteEnter(to, from, next) {
    // we need to account for multiple screens that route to ScanBeacon, in
    // order to be able to route back (eg.: click back button)
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called
    next((vm) => {
      // access to component instance via `vm`
      // eslint-disable-next-line no-param-reassign
      vm.fromRouteName = from.name
    })
  },
  computed: {
    ...mapGetters('patientsModule', ['donatePatient']),
    ...mapGetters('patientGroupModule', ['primaryPatientId']),
    ...mapGetters('newTicketModule', [
      'currentTicketStep',
      'selectedMethod',
      'selectedSpecimenType',
      'selectedPatient',
      'procedureDateFrom',
      'procedureDateTo',
      'beacons',
      'selectedCryoDeviceType',
      'batchSharedPatientIds',
      'metadata', // select-reason -> export
      'robotTransfer', // cryobeacon-mixin/add-existing-beacon?
      'shippingSourceId', // how-many -> import
      'donorId' // how-many -> import
    ]),
    ...mapGetters('authModule', ['appBaseConfig', 'loggedUserInfo']),
    ...mapGetters('specimensModule', ['embryoTypes', 'specimens']),
    ...mapGetters('patientGroupModule', ['patients']),
    ...mapGetters('ticketsModule', ['selectedDestination']),
    ...mapGetters('ticketModule', ['ticketCreationState']),
    eggHeaders() {
      return dynamicEgg({})
    },
    embryoHeaders() {
      return dynamicEmbryo({})
    },
    headers() {
      const embryoHeaders = dynamicEmbryo({})
      const oocyteHeaders = dynamicEgg({})

      if (this.selectedSpecimenType === SPECIMEN_TYPE_EMBRYO) {
        return embryoHeaders
      }

      return oocyteHeaders
    },
    getActiveBreadcrumbs() {
      const currentBreadcrumbs = getBreadcrumbs({
        currentTicketStep: this.currentTicketStep,
        selectedMethod: this.selectedMethod
      })
      return currentBreadcrumbs
    },
    breadcrumbsItems() {
      return [
        {
          label: 'Specimens',
          active: false
        },
        {
          label: 'Reason',
          active: false
        },
        {
          label: 'Confirm',
          active: true
        }
      ]
    },
    robotDestinationTitle() {
      return `Choose ${CRYOROBOT} Destination`
    },
    pageTitle() {
      return this.hasCryoRobotSelector ? this.robotDestinationTitle : this.dynamicPageTitle
    },
    pageSubtitle() {
      return this.hasCryoRobotSelector ? this.dynamicPageTitle : this.robotDestinationTitle
    },
    defaultOptionLabel() {
      return `Choose ${CRYOROBOT}`
    },
    isFreeze() {
      return this.selectedMethod === METHOD_FREEZE
    },
    isImport() {
      return this.selectedMethod === METHOD_IMPORT
    },
    isExport() {
      return this.selectedMethod === METHOD_EXPORT
    },
    isBatchTicket() {
      return this.selectedMethod === PROCEDURE_TYPE_BATCH_UPPERCASE
    },
    hasTicketIds() {
      return this.ticketIds.length
    },
    getItemsToPrintPerPage() {
      const maxItemsPerPage = 1
      const currentPageToShow = this.currentActivePage * maxItemsPerPage
      const start = currentPageToShow - maxItemsPerPage
      return this.ticketsToDisplay.slice(start, currentPageToShow).map(this.filterBatchCryodevices)
    },
    currentTicketId() {
      return this.ticketIds.length && !this.isBatchTicket
        ? `${this.ticketIds[this.currentActivePage - 1].ticketId}`
        : ''
    },
    isMultiplePatientsEnabled() {
      const multiplePatientsEnabled = isFeatureEnabled(MULTIPLE_PATIENTS_ENABLED_FF)
      return multiplePatientsEnabled
    },
    selectedTicketIndex() {
      return this.currentActivePage - 1
    },
    newTicketData() {
      if (this.isBatchTicket && !this.hasTicketIds) {
        return this.filterBatchCryodevices(this.ticketsToDisplay[this.selectedTicketIndex])
      }
      if (!this.newTicketsData || !this.newTicketsData.length) {
        return null
      }
      return this.newTicketsData[this.selectedTicketIndex]
    },
    ticketCreationPayload() {
      if (this.sharedPatientIds?.length) {
        return {
          ...this.ticketCreationState,
          sharedPatientIds: this.sharedPatientIds
        }
      }
      return this.ticketCreationState
    },

    hasCryoRobotSelector() {
      const subType = ss.getFieldSessionStorage('batch', 'procedureSubType')
      return this.isBatchTicket && !this.hasTicketIds && subType === ROBOT_TRANSFER_IN
    },

    isThawProcedure() {
      return this.selectedMethod === METHOD_THAW
    },
    getSpecimensRemaining() {
      if (this.isBatchTicket) {
        return this.ticketsToDisplay[this.selectedTicketIndex].cryoDevice.filter(
          ({ inSelectedTicket }) => !inSelectedTicket
        )
      }
      const enabledSpecimens = this.specimens.all.filter((specimen) => !specimen.disabled)
      const { beaconBarcode } = this.beacons[this.selectedTicketIndex]

      const cryoDevices = this.beacons[this.selectedTicketIndex].cryoDevice.map(
        (cryoDevice) => cryoDevice.cryodeviceBarcode
      )
      // Filter specimens from selected beacon by comparing Beacon barcode (Should be equal)
      // and cryodevice barcode (Should be different, meaning, remaining specimens)
      return enabledSpecimens
        .filter((specimen) => {
          return (
            specimen.beaconBarcode === beaconBarcode &&
            !cryoDevices.includes(specimen.cryodeviceBarcode)
          )
        })
        .map((specimen) => ({ ...specimen, disabled: true }))
    },

    embryoSpecimens() {
      const embryosModel = {
        ...this.ticketDetailModel,
        options: this.getSpecimensRemaining.filter(
          (item) => item.specimenType === SPECIMEN_TYPE_EMBRYO
        )
      }
      return embryosModel
    },
    eggSpecimens() {
      const eggsModel = {
        ...this.ticketDetailModel,
        options: this.getSpecimensRemaining.filter(
          (item) =>
            item.specimenType === SPECIMEN_TYPE_EGG || item.specimenType === SPECIMEN_TYPE_OOCYTE
        )
      }
      return eggsModel
    },
    showRemainingSpecimens() {
      // Note: Maybe is not necessary to validate procedure type, and only validate getSpecimensRemaining
      const allowRemainMethods = [PROCEDURE_TYPE_BATCH_UPPERCASE, METHOD_THAW, METHOD_EXPORT]
      return allowRemainMethods.includes(this.selectedMethod) && !!this.getSpecimensRemaining.length
    },
    defaultDestinationValue() {
      const defaultCryorobot = this.destinationOptions.find((cryorobot) => cryorobot.defaultRobot)
      return this.selectedDestinationBatch || (!this.isBatchTicket && defaultCryorobot?.value) || ''
    }
  },
  methods: {
    ...mapActions('beaconsModule', ['setBeaconBarcode']),
    ...mapActions('newTicketModule', ['createTickets']),
    ...mapActions('specimensModule', ['fetchEmbryoTypes']),
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    ...mapActions('patientsModule', ['getExternalClinics']),
    ...mapActions('selectedTicketsModule', ['fetchTicketsInfo', 'fetchTicketsDetail']),
    ...mapActions('ticketModule', ['applyCryoDateToCryoDevice']),
    filterBatchCryodevices(ticket) {
      return {
        ...ticket,
        cryoDevice: this.isBatchTicket
          ? ticket.cryoDevice.filter(({ inSelectedTicket }) => inSelectedTicket)
          : ticket.cryoDevice
      }
    },
    updateSpecimenTypeWhenExport() {
      const isExport =
        this.ticketsToPost[0].procedureType &&
        this.ticketsToPost[0].procedureType.includes(METHOD_EXPORT_UPPER)

      if (isExport) {
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          specimenType,
          ...restOfTicketsToPost
        } = this.ticketsToPost[0]

        return restOfTicketsToPost
      }

      return this.ticketsToPost[0]
    },
    updateCryodateWhenImport() {
      const isImport =
        this.ticketsToPost[0].procedureType &&
        this.ticketsToPost[0].procedureType.includes(PROCEDURE_TYPE_SINGLE_IMPORT)

      if (isImport) {
        const cryoDevice = this.ticketsToPost[0].cryoDevice.map((cryodevice) => ({
          ...cryodevice,
          cryoDate: dayjs.utc(cryodevice.cryoDate).valueOf()
        }))

        return { ...this.ticketsToPost[0], cryoDevice }
      }

      return this.ticketsToPost[0]
    },
    getTicketLocationsAfterCreation() {
      this.ticketsToDisplay = this.ticketsToDisplay.map((ticket) => {
        const newData = this.newTicketsData.find(
          (finalTicket) => finalTicket.beaconBarcode === ticket.cryoDevice[0].beaconBarcode
        )
        return { ...ticket, robotLocations: newData.robotLocations }
      })
    },
    async createNewTickets(ticketsToCreate) {
      const response = await this.createTickets(ticketsToCreate)
      this.ticketIds = response.data

      toast.success('Ticket Created Successfully')

      if (ss.getFieldSessionStorage('newBeaconsArray')) {
        ss.removeFieldSessionStorage('newBeaconsArray')
      }

      const ticketData = await this.getPDFticketsData()
      this.newTicketsData = ticketData

      if (this.isBatchTicket) {
        this.getTicketLocationsAfterCreation()
        this.dynamicPageTitle = `Your Batch Ticket has been created - #${this.ticketIds[0].ticketId}`
      } else {
        this.dynamicPageTitle = 'Your ticket has been created!'
      }
    },
    async createTicket() {
      this.displaySpinner('Finishing the ticket')

      if (!this.isBatchTicket) {
        this.ticketsToPost = await this.buildTicketsToPost(true)
        this.ticketsToPost[0] = this.updateSpecimenTypeWhenExport()
        this.ticketsToPost[0] = this.updateCryodateWhenImport()

        try {
          await this.createNewTickets(this.ticketsToPost)
          this.resetDestination()
          this.ticketHasBeenCreated = true
        } catch (err) {
          toast.error({ title: err.message })

          if (err.response && err.response.data) {
            err.response.data.forEach((item) => {
              toast.error({ title: item.message })
            })
          }
        } finally {
          this.hideSpinner()
        }
      } else {
        // BATCH TICKETS
        try {
          this.noDestinationSelected = false
          const procedureDateFromTimestamp = dayjs().utc().valueOf() + 600
          // Compose ticket payload
          const procedureSubType = ss.getFieldSessionStorage('batch', 'procedureSubType') || ''
          const children = ss.getFieldSessionStorage('batch', 'childrens')
          const cpId = ss.getFieldSessionStorage('batch', 'cpId')
          const userId = ss.getFieldSessionStorage('batch', 'userId')
          const composedTicket = [
            {
              eventId: 0,
              procedureType: PROCEDURE_TYPE_BATCH_UPPERCASE,
              robotTransfer: procedureSubType,
              userId,
              cpId,
              visit: {
                procedureDateFrom: procedureDateFromTimestamp,
                procedureDateTo: this.procedureDateTo || null
              },
              source: 1,
              children
            }
          ]

          if (this.selectedDestinationBatch) {
            await this.validateDestination()
            composedTicket[0].unitId = this.selectedDestinationBatch
          } else {
            this.noDestinationSelected = this.hasCryoRobotSelector
            this.setUnitSpaceValidationFailed(false)
          }
          if (!this.noDestinationSelected && !this.unitSpaceValidationFailed) {
            await this.createNewTickets(composedTicket)
            this.ticketHasBeenCreated = true
          }
        } catch (err) {
          toast.error({ title: err.message })

          if (err.message && err.message === 'NOT_ENOUGH_SPACE') {
            this.isCustomModalOpen = true
          }
        } finally {
          this.hideSpinner()
        }
      }
      return this
    },
    async validateDestination() {
      const validationPayload = {
        screeningStatus: this.beacons[0].cryoDevice[0].screeningStatus,
        containerId: this.selectedDestination
      }

      await this.validateUnitSpace(validationPayload)
    },
    getPrinterName(printerName) {
      return setPrinterName(printerName)
    },
    closePrinterModal() {
      this.isPrinterModalVisible = false
    },
    showPrinterModal() {
      this.isPrinterModalVisible = true
    },
    getUpdatedTicketData({ basicData, currentPatient, currentNewBeaconsArray, sharedPatientIds }) {
      const isDonateToPatient = this.donatePatient.clinicId && this.donatePatient.siteId

      const donationMetadata = {
        exportReason: {
          type: 'DONATION',
          details: {
            metadata: {
              beaconBarcode: currentNewBeaconsArray,
              patient: this.donatePatient
            },
            subType: DONATE_TO_PATIENT_VALUE
          }
        }
      }

      const metadata = isDonateToPatient ? donationMetadata : basicData.metadata

      if (this.selectedMethod === PROCEDURE_TYPE_BATCH_UPPERCASE) {
        // For normal tickets, we are always using the same user,
        // but for batch, we can have tickets for multiple users
        const updatedBasicData = {
          ...basicData,
          patient: {
            firstName: currentPatient.firstName,
            lastName: currentPatient.lastName,
            identificationNumber: currentPatient.identificationNumber,
            birthDate: currentPatient.birthDate
          },
          sharedPatientIds,
          metadata
        }
        return updatedBasicData
      }
      if (this.isThawProcedure || this.isExport) {
        // For thaw tickets, we use the patient of the cryodevice
        const updatedBasicData = {
          ...basicData,
          globalPatientNumber: currentPatient.globalPatientNumber,
          patient: {
            firstName: currentPatient.firstName,
            lastName: currentPatient.lastName,
            identificationNumber: currentPatient.identificationNumber,
            birthDate: currentPatient.birthDate
          },
          sharedPatientIds,
          metadata
        }
        return updatedBasicData
      }

      return { ...basicData, metadata }
    },
    updateBeaconBarcode({ basicData, currentNewBeaconsArray, newCryoDevice }) {
      if (
        basicData.procedureType.toLowerCase() === METHOD_FREEZE_LOWERCASE ||
        basicData.procedureType === SINGLE_IMPORT_CONST
      ) {
        return {
          ...newCryoDevice,
          beaconBarcode: currentNewBeaconsArray
        }
      }
      return newCryoDevice
    },
    setSpecimenDetails(cryoDeviceItem) {
      if (cryoDeviceItem.specimenType === SPECIMEN_TYPE_EMBRYO) {
        const embryoDetails = {
          embryo: {
            biopsy: cryoDeviceItem.biopsy || cryoDeviceItem.embryo?.biopsy,
            biopsyResult: cryoDeviceItem.biopsyResult || cryoDeviceItem.embryo?.biopsyResult,
            embryoNumber: cryoDeviceItem.embryoNumber || cryoDeviceItem.embryo?.embryoNumber,
            embryoType: cryoDeviceItem.embryoType || cryoDeviceItem.embryo?.embryoType,
            grade: cryoDeviceItem.grade || cryoDeviceItem.embryo?.grade
          }
        }

        const filteredEmbryoDetails = removeKeysWithNullValues(embryoDetails)

        return filteredEmbryoDetails
      }

      const oocyteDetails = {
        oocyte: {
          maturityLevel: cryoDeviceItem?.oocyte?.maturityLevel
        }
      }

      const filteredOocyteDetails = removeKeysWithNullValues(oocyteDetails)

      return filteredOocyteDetails
    },
    setSourceDetails() {
      if (this.selectedMethod === METHOD_IMPORT) {
        return {
          source: {
            sourceTypeId: '1',
            shippingSourceId: this.shippingSourceId,
            donorId: this.donorId
          }
        }
      }

      return null
    },
    setEmbryoTypeLabelFields({ additionalSpecimenFields, objectToAPI }) {
      if (!objectToAPI) {
        const embryoTypeLabel = selectEmbryoTypeLabel(additionalSpecimenFields, this.embryoTypes)

        return embryoTypeLabel
      }

      return null
    },
    async buildTicketsToPost(objectToAPI = false) {
      try {
        const { userId } = this.loggedUserInfo
        const { collectionProtocolId } = this.appBaseConfig

        const [{ firstName, lastName, identificationNumber, birthDate, globalPatientNumber }] =
          this.selectedPatient

        const procedureType =
          this.selectedMethod === METHOD_IMPORT
            ? SINGLE_IMPORT_CONST
            : this.selectedMethod.toUpperCase()
        const specimenType =
          this.selectedSpecimenType !== null
            ? this.getSpecimenType(this.selectedSpecimenType)
            : null
        const now = dayjs().valueOf()
        const getPocedureDateFrom = this.procedureDateFrom || now
        const getProcedureDateTo = this.procedureDateTo

        let basicData = {
          robotTransfer: this.robotTransfer,
          cpId: collectionProtocolId,
          eventId: 0,
          procedureType,
          globalPatientNumber,
          specimenAction: null,
          specimenType,
          userId,
          visit: {
            procedureDateFrom: getPocedureDateFrom,
            procedureDateTo: getProcedureDateTo
          },
          patient: {
            firstName,
            lastName,
            identificationNumber,
            birthDate
          },
          // For every new ticket created in ClinicUI the 'source' must be 1,
          // that means the ticket is not EMR
          source: 1,
          metadata: { ...this.metadata }
        }

        // Single Import procedure type needs to set the procedureDateFrom to now
        // as these tickets do not go over the calendar view
        if (procedureType === PROCEDURE_TYPE_SINGLE_IMPORT) {
          basicData = {
            ...basicData,
            visit: {
              ...basicData.visit,
              procedureDateFrom: now
            }
          }
        }

        if (this.sharedPatientIds.length) {
          basicData = {
            ...basicData,
            sharedPatientIds: this.sharedPatientIds
          }
        }

        if (this.beacons?.length) {
          const additionalSourceFields = this.setSourceDetails()
          let currentPatients = this.selectedPatient
          let currentSharedPatients = this.batchSharedPatientIds
          if (this.isThawProcedure || this.isExport) {
            const patientsIds = this.beacons.map(({ cryoDevice: [{ cprId }] }) => cprId)
            currentSharedPatients = this.beacons.map(
              ({ cryoDevice: [{ sharedPatientIds }] }) => sharedPatientIds
            )
            currentPatients = await fetchPatientsById(patientsIds)
          }

          const newBeacons = this.beacons.map((beacon, index) => {
            const newBeaconsArray = ss.getFieldSessionStorage('newBeaconsArray')
              ? JSON.parse(ss.getFieldSessionStorage('newBeaconsArray'))
              : []

            const ticketData = this.getUpdatedTicketData({
              basicData,
              currentPatient: currentPatients[index],
              currentNewBeaconsArray: newBeaconsArray[index],
              sharedPatientIds: currentSharedPatients[index]
            })
            const unitId = beacon.selectedDestination

            const cryoDevice = beacon.cryoDevice.map((cryoDeviceItem) => {
              const {
                // biopsy,
                // embryoNumber,
                // embryoType,
                // embryo,
                // oocyte,
                // cryodeviceBarcodeValidation,
                freeText,
                metadata,
                ...newCryoDevice
              } = cryoDeviceItem

              const updatedNewCryoDevice = this.updateBeaconBarcode({
                basicData,
                currentNewBeaconsArray: newBeaconsArray[index],
                newCryoDevice
              })

              const additionalSpecimenFields = this.setSpecimenDetails(cryoDeviceItem)

              const additionalEmbryoTypeLabelFields = this.setEmbryoTypeLabelFields({
                additionalSpecimenFields,
                objectToAPI
              })

              const cryodeviceTypeId = this.selectedCryoDeviceType && this.selectedCryoDeviceType.id
              const finalMeta =
                freeText?.length > 0
                  ? {
                      ...(metadata || {}),
                      metadata: {
                        ...(metadata?.metadata || {}),
                        cryolabelFreeText: freeText
                      }
                    }
                  : metadata
              return {
                cryoDate: this.procedureDateFrom,
                ...updatedNewCryoDevice,
                ...additionalSpecimenFields,
                ...additionalSourceFields,
                ...additionalEmbryoTypeLabelFields,
                cryodeviceTypeId,
                metadata: finalMeta
              }
            })

            return {
              cryoDevice,
              ...ticketData,
              unitId,
              robotLocations: beacon.robotLocations,
              inventoryUpdate: beacon.inventoryUpdate
            }
          })
          return newBeacons
        }

        // Scheduled - No Specimens required
        return [
          {
            ...basicData,
            cryoDevice: []
          }
        ]
      } catch (error) {
        throw new Error(error)
      }
    },
    async sendPrintJob() {
      if (this.selectedPrinter === '0') {
        this.printPDFTicket()
        return
      }
      const selectedPrinter = this.activePrinters.find(
        (printer) => printer.uid === this.selectedPrinter
      )
      if (!selectedPrinter) {
        console.error('Selected printer not found')
        return
      }
      try {
        await printTickets(selectedPrinter, this.newTicketsData)
        this.closePrinterModal()
      } catch (error) {
        console.error('Error sending print job: ', error)
      }
    },
    async getPDFticketsData() {
      const ticketData = this.ticketIds[0].children?.length
        ? this.ticketIds[0].children
        : this.ticketIds.map((ticket) => ticket.ticketId)

      const response = await getTicketApi({ ticketId: ticketData.join(',') })

      return response.data
    },
    async printPDFTicket() {
      let params = {}
      switch (this.selectedMethod) {
        case PROCEDURE_TYPE_BATCH_UPPERCASE: {
          const sessionCache = await ss.getFieldSessionStorage('batch', 'cache')
          params = {
            selectedTickets: sortSpecimensByCryodeviceId(sessionCache || []),
            selectedTicketsToPrint: sortSpecimensByCryodeviceId(sessionCache || []),
            externalClinics: this.externalClinics
          }
          break
        }
        default: {
          params = {
            selectedTickets: sortSpecimensByCryodeviceId(this.ticketsToDisplay || []),
            selectedTicketsToPrint: sortSpecimensByCryodeviceId(this.newTicketsData || []),
            externalClinics: this.externalClinics
          }
          break
        }
      }

      selectedTicketToPdf(params)
      this.closePrinterModal()
    },
    onChangeTicketToView(page) {
      // need it for reprint label on batch ticket
      ss.setFieldSessionStorage('activeSlideSelectedTickets', page, 'process')
      this.currentActivePage = page
    },
    getSpecimenType(specimenType) {
      if (!specimenType || this.selectedMethod === PROCEDURE_TYPE_BATCH_UPPERCASE) return null

      if (specimenType === SPECIMEN_TYPE_EGGS || specimenType === SPECIMEN_TYPE_OOCYTE) {
        return SPECIMEN_TYPE_EGG
      }

      return specimenType.toUpperCase()
    },
    handleClickBack() {
      const previousStepPath = executePreviousTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        fromRouteName: this.fromRouteName
      })

      this.$router.replace({ name: previousStepPath })
    },
    async returnToFligthBoard() {
      // TODO, This needs cleanup and refactor

      this.ticketHasBeenCreated = false
      let tickets = this.ticketsToDisplay
      if (this.selectedMethod === PROCEDURE_TYPE_BATCH_UPPERCASE) {
        tickets = await ss.getFieldSessionStorage('batch', 'cache')
      }
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        ticketData: tickets[0]
      })
      this.$router.push({ name: nextStepPath })
    },
    hideCustomizableModal() {
      this.isCustomModalOpen = false
    },
    handleSelectDestination(value) {
      this.onSelectDestination(value)
      this.noDestinationSelected = false
    }
  }
}
</script>
