// import Vue from 'vue'
import Vuex from 'vuex'
import { createRouter, createWebHistory } from 'vue-router'
import * as ss from '@/config/session-storage-help'
import AdminView from '@/views/Admin/Admin.vue'
import ModularLabelView from '@/views/ModularLabelView/ModularLabelView.vue'
import DefaultCryorobotView from '@/views/DefaultCryorobotView/DefaultCryorobotView.vue'
import AddSharedPatient from '@/views/patients/AddSharedPatient/AddSharedPatient.vue'
import MainLayout from '@/components/MainLayout/MainLayout.vue'
import Login from '@/views/Login/Login.vue'
import ResetPassword from '@/views/ResetPassword/ResetPassword.vue'
import ForgotPassword from '@/views/ForgotPassword/ForgotPassword.vue'
import FlightBoard from '@/views/FlightBoard/FlightBoard.vue'
import InventoryUpdate from '@/views/InventoryUpdate/InventoryUpdate.vue'
import CalendarBoard from '@/views/CalendarView/CalendarView.vue'
import EditDateCalendar from '@/views/EditDateCalendar/EditDateCalendar.vue'
import SelectedTickets from '@/views/SelectedTickets/SelectedTickets.vue'
import FinalTicket from '@/views/FinalTicket/FinalTicket.vue'
import SelectSpecimens from '@/views/SelectSpecimens/SelectSpecimens.vue'
import SelectSpecimensForNewTicket from '@/views/SelectSpecimensForNewTicket/SelectSpecimensForNewTicket.vue'
import SpecimenDetail from '@/views/SpecimenDetail/SpecimenDetail.vue'
import PrintLabelTest from '@/views/PrintLabelTest/PrintLabelTest.vue'
import HowMany from '@/views/HowMany/HowMany.vue'
import ScanBeaconView from '@/views/ScanBeaconView/ScanBeaconView.vue'
import ScanBeaconDonationView from '@/views/ScanBeaconDonationView/ScanBeaconDonationView.vue'
import AddExistingBeacon from '@/views/AddExistingBeaconView/AddExistingBeaconView.vue'
import LabelScanView from '@/views/LabelScanView/LabelScanView.vue'
import Details from '@/views/Details/Details.vue'
import SelectEmbryologist from '@/views/SelectEmbryologist/SelectEmbryologist.vue'
import SelectProcedureDateTime from '@/views/SelectProcedureDateTime/SelectProcedureDateTime.vue'
import PatientInventory from '@/views/PatientInventory/PatientInventory.vue'
import Tickets from '@/views/Tickets/Tickets.vue'
import PrintersStatus from '@/views/PrintersStatus/PrintersStatus.vue'
import ErrorsLog from '@/views/ErrorsLog/ErrorsLog.vue'
import { ADMIN_ROLES, USER_ADMINISTRATOR } from '@/constants'
import UpdateScreeningStatus from '@/views/UpdateScreeningStatus/UpdateScreeningStatus.vue'
import CustomCalendarDemo from '@/components/CustomCalendar/CustomCalendarDemo.vue'
import UserDetails from '@/views/users/UserDetails/UserDetails.vue'
import UsersList from '@/views/users/UsersList/UsersList.vue'
import EditUser from '@/views/users/EditUser/EditUser.vue'
import CreateUser from '@/views/users/CreateUser/CreateUser.vue'
import PatientList from '@/views/patients/PatientList/PatientList.vue'
import PatientView from '@/views/patients/PatientView/PatientView.vue'
import PatientForm from '@/views/patients/PatientForm/PatientForm.vue'
import SelectProcedure from '@/views/SelectProcedure/SelectProcedure.vue'
import SelectSpecimenType from '@/views/SelectSpecimenType/SelectSpecimenType.vue'
import SelectThawProcedure from '@/views/SelectThawProcedure/SelectThawProcedure.vue'
import SelectPatient from '@/views/SelectPatient/SelectPatient.vue'
import SelectSpecimensToExport from '@/views/SelectSpecimensToExport/SelectSpecimensToExport.vue'
import SelectReasonToExport from '@/views/SelectReasonToExport/SelectReasonToExport.vue'
import spinnerModule from '@/store/modules/spinner'
import modalModule from '@/store/modules/modal'
import BatchTicket from '@/views/BatchTicket/BatchTicket.vue'
import BatchTicketType from '@/views/BatchTicketType/BatchTicketType.vue'
import CancelTicket from '@/views/CancelTicket/CancelTicket.vue'
import BiorepositoryRelocation from '@/views/Biorepository/Relocation/Relocation.vue'
import BiorepositoryFlightBoard from '@/views/Biorepository/FlightBoard/FlightBoard.vue'
import BiorepositorySelectLocation from '@/views/Biorepository/SelectLocation/SelectLocation.vue'
import BiorepositorySelectRelocationTickets from '@/views/Biorepository/SelectRelocationTickets/SelectRelocationTickets.vue'
// import BiorepositorySelectPatient from '@/views/Biorepository/SelectPatient/SelectPatient.vue'
import BiorepositorySelectProcedure from '@/views/Biorepository/SelectProcedure/SelectProcedure.vue'
import BiorepositorySendTicket from '@/views/Biorepository/SendTicket/SendTicket.vue'
import BiorepositoryAddCryobeacons from '@/views/Biorepository/AddCryobeacons/AddCryobeacons.vue'
import BiorepositoryTicketCreated from '@/views/Biorepository/TicketsCreated/TicketsCreated.vue'
import BiorepositoryCryobeaconDetails from '@/views/Biorepository/CryoBeaconDetails/CryoBeaconDetails.vue'
import BiorepositoryCryoShipperConfirm from '@/views/Biorepository/CryoShipperConfirm/CryoShipperConfirm.vue'

import {
  ADD_EXISTING_BEACON_ROUTE,
  ADD_SHARED_PATIENT_ROUTE,
  EDIT_DATE_ROUTE,
  SCAN_BEACON_ROUTE,
  SELECT_SPECIMENS_ROUTE,
  SELECT_SPECIMENS_TO_EXPORT_ROUTE,
  INVENTORY_UPDATE_ROUTE,
  PATIENT_VIEW_ROUTE,
  SELECT_EMRBYOLOGIST_ROUTE
} from '@/constants/routes'
import {
  STEP_CALENDAR,
  STEP_DETAILS,
  STEP_FINAL,
  STEP_HOW_MANY,
  STEP_LABEL_SCAN,
  STEP_SCAN_BEACON,
  STEP_SELECT_PATIENT,
  STEP_SELECT_PROCEDURE,
  STEP_SELECT_SPECIMEN_TYPE,
  STEP_ADD_EXISTING_BEACON,
  STEP_SELECT_SPECIMENS,
  STEP_SELECT_SPECIMENS_NEW_TICKET,
  STEP_SELECT_THAW_PROCEDURE,
  STEP_SELECT_REASON_TO_EXPORT,
  STEP_BATCH_TICKET_TYPE,
  STEP_NEW_BATCH_TICKET,
  STEP_SELECTED_TICKET
} from '@/constants/ticketSteps'
import { app } from '@/main'
import { EmrFactory, FeatureFlag } from '@/factories'

const store = new Vuex.Store({
  modules: {
    spinnerModule,
    modalModule
  }
})

const storage = ss

// Vue.use(Router)

/**
 * Signatre of all route guards:
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 *
 * See http://router.vuejs.org/en/advanced/navigation-guards.html
 * for more details.
 */
function hiddenUrlValidation(to, from, next) {
  if (import.meta.env.NODE_ENV === 'development') {
    next()
  } else {
    next(from.path)
  }
}

function adminValidation(to, from, next) {
  const userType = storage.getFieldSessionStorage('user', 'userType')
  if (ADMIN_ROLES.indexOf(userType) !== -1) {
    next()
  } else {
    next(from.path)
  }
}

function isBatchEnabled(to, from, next) {
  const getBatchFlag = new FeatureFlag(import.meta.env.VITE_BATCH_FF)
  const isBatchFlagEnabled = getBatchFlag.getValue()
  if (isBatchFlagEnabled) {
    return next()
  }
  return next('/')
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: MainLayout,
      children: [
        {
          path: '/',
          component: Login,
          name: 'Login',
          meta: { allowGuests: true }
        },
        {
          path: '/admin',
          component: AdminView,
          beforeEnter: adminValidation
        },
        {
          path: '/admin/cryolabels',
          component: ModularLabelView,
          beforeEnter: adminValidation
        },
        {
          path: '/admin/cryorobot',
          component: DefaultCryorobotView,
          beforeEnter: adminValidation
        },
        {
          path: '/flight-board',
          name: 'FlightBoard',
          component: FlightBoard
        },
        {
          path: '/printers-status',
          component: PrintersStatus
        },
        {
          path: '/errors-log',
          component: ErrorsLog
        },
        {
          path: INVENTORY_UPDATE_ROUTE(),
          component: InventoryUpdate,
          props: true
        },
        {
          path: '/forgot-password',
          component: ForgotPassword,
          meta: { allowGuests: true }
        },
        {
          path: '/calendar',
          component: CalendarBoard,
          name: STEP_CALENDAR
        },
        {
          path: EDIT_DATE_ROUTE,
          component: EditDateCalendar
        },
        {
          path: '/select-procedure',
          name: STEP_SELECT_PROCEDURE,
          component: SelectProcedure
        },
        {
          path: '/select-specimen-type',
          name: STEP_SELECT_SPECIMEN_TYPE,
          component: SelectSpecimenType
          // beforeEnter: createTicketAllowed
        },
        {
          path: '/select-thaw-procedure',
          name: STEP_SELECT_THAW_PROCEDURE,
          component: SelectThawProcedure
          // beforeEnter: createTicketAllowed
        },
        {
          path: '/select-patient',
          component: SelectPatient,
          name: STEP_SELECT_PATIENT
          // beforeEnter: createTicketAllowed
        },
        {
          path: '/selected-tickets',
          name: STEP_SELECTED_TICKET,
          component: SelectedTickets
        },
        {
          path: '/final-ticket',
          name: STEP_FINAL,
          component: FinalTicket
        },
        {
          path: SELECT_SPECIMENS_ROUTE,
          name: STEP_SELECT_SPECIMENS,
          component: SelectSpecimens
        },
        {
          path: '/select-specimens-for-new-ticket',
          name: STEP_SELECT_SPECIMENS_NEW_TICKET,
          component: SelectSpecimensForNewTicket
        },
        {
          path: SELECT_SPECIMENS_TO_EXPORT_ROUTE,
          component: SelectSpecimensToExport
        },
        {
          path: '/select-reason-to-export',
          name: STEP_SELECT_REASON_TO_EXPORT,
          component: SelectReasonToExport
        },
        {
          path: '/patients/create',
          component: PatientForm
        },
        {
          path: '/patients/update/:patientId',
          component: PatientForm
        },
        {
          path: PATIENT_VIEW_ROUTE(),
          name: 'patient-view',
          component: PatientView
        },
        {
          path: '/patients-search',
          component: PatientList
        },
        {
          path: '/user/update/:id',
          component: EditUser,
          beforeEnter: adminValidation
        },
        {
          path: '/user/create',
          component: CreateUser,
          beforeEnter: adminValidation
        },
        {
          path: '/user/details',
          component: UserDetails,
          beforeEnter: adminValidation
        },
        {
          path: '/users-list',
          component: UsersList,
          beforeEnter: adminValidation
        },
        {
          path: '/reset-password/:token',
          component: ResetPassword,
          meta: { allowGuests: true }
        },
        {
          path: '/specimen-detail/:patientId/:specimenId',
          component: SpecimenDetail
        },
        {
          path: ADD_SHARED_PATIENT_ROUTE(),
          component: AddSharedPatient
        },
        {
          path: '/patient-inventory/:patientId',
          component: PatientInventory
        },
        {
          path: '/update-screening-status/:patientId/:beaconBarcode',
          component: UpdateScreeningStatus
        },
        {
          path: '/print-label-test',
          component: PrintLabelTest,
          beforeEnter: hiddenUrlValidation,
          meta: { allowGuests: true }
        },
        {
          path: '/how-many',
          name: STEP_HOW_MANY,
          component: HowMany
        },
        {
          path: '/details',
          name: STEP_DETAILS,
          component: Details
        },
        {
          path: '/label-scan',
          name: STEP_LABEL_SCAN,
          component: LabelScanView
        },
        {
          path: SCAN_BEACON_ROUTE,
          name: STEP_SCAN_BEACON,
          component: ScanBeaconView
        },
        {
          path: '/scan-beacon-donation',
          component: ScanBeaconDonationView
        },
        {
          path: ADD_EXISTING_BEACON_ROUTE,
          name: STEP_ADD_EXISTING_BEACON,
          component: AddExistingBeacon
        },
        {
          path: SELECT_EMRBYOLOGIST_ROUTE(),
          component: SelectEmbryologist
        },
        {
          path: '/select-procedure-date-time',
          component: SelectProcedureDateTime
        },
        {
          path: '/tickets/:ticketId',
          component: Tickets
        },
        {
          path: '/custom-calendar',
          component: CustomCalendarDemo,
          beforeEnter: hiddenUrlValidation,
          meta: { allowGuests: true }
        },
        {
          path: '/new-batch-ticket',
          component: BatchTicket,
          name: STEP_NEW_BATCH_TICKET,
          beforeEnter: isBatchEnabled
        },
        {
          path: '/batch-ticket-type',
          component: BatchTicketType,
          name: STEP_BATCH_TICKET_TYPE,
          beforeEnter: isBatchEnabled
        },
        {
          path: '/cancel-ticket',
          component: CancelTicket
        },
        {
          path: '/biorepository/new',
          name: 'BiorepositoryNewTicket',
          component: BiorepositoryRelocation
        },
        {
          path: '/biorepository/flight-board',
          name: 'BiorepositoryFlightBoard',
          component: BiorepositoryFlightBoard
        },
        {
          path: '/biorepository/select-procedure',
          name: 'BiorepositoryTicketTypeSelection',
          component: BiorepositorySelectProcedure
        },
        {
          path: '/biorepository/send-ticket',
          name: 'BiorepositorySendTicket',
          component: BiorepositorySendTicket
        },
        {
          path: '/biorepository/add-cryobeacons',
          name: 'BiorepositoryAddCryobeacons',
          component: BiorepositoryAddCryobeacons
        },
        {
          path: '/biorepository/select-tickets',
          name: 'BiorepositorySelectRelocationTickets',
          component: BiorepositorySelectRelocationTickets
        },
        {
          path: '/biorepository/cryoshipper-confirm',
          name: 'BiorepositoryCryoShipperConfirm',
          component: BiorepositoryCryoShipperConfirm
        },
        {
          path: '/biorepository/tickets-created',
          name: 'BiorepositoryTicketCreated',
          component: BiorepositoryTicketCreated
        },
        {
          path: '/biorepository/cryobeacon-details/:cryobeaconBarcode',
          name: 'BiorepositoryCryobeaconDetails',
          component: BiorepositoryCryobeaconDetails,
          props: true
        },
        // {
        //   path: '/biorepository/select-patient',
        //   name: 'BiorepositorySelectPatient',
        //   component: BiorepositorySelectPatient
        // },
        {
          path: '/biorepository/select-location',
          name: 'BiorepositorySelectLocation',
          component: BiorepositorySelectLocation
        },
        {
          path: '/biorepository/relocation-ticket',
          name: 'BiorepositoryMoveTicket',
          component: SelectedTickets
        },
        {
          path: '/stack-selected-tickets',
          name: 'StackSelectedTicked',
          component: SelectedTickets
        }
      ]
    }
  ]
})

router.afterEach((to) => {
  // TODO: Don't see the point of this, the routesHistory just keeps increasing and not being used
  // should be refactored or removed

  let storedRoutes = storage.getFieldSessionStorage('routesHistory')
  if (storedRoutes) {
    if (storedRoutes.length > 0 && storedRoutes[storedRoutes.length - 1] !== to.path) {
      storedRoutes.push(to.path)
    }
  } else {
    storedRoutes = []
    storedRoutes.push(to.path)
  }
  storage.setFieldSessionStorage('routesHistory', storedRoutes)
})

router.beforeEach(async (to, from, next) => {
  // EMR Enabled Transactions and Data Sync
  const config = storage.getFieldSessionStorage('config')
  const emr = new EmrFactory(config)
  const isSiteEmrEnabled = emr.smsPermissionsEnabled()

  // setup a global reference in order to make it available on every path
  app.config.globalProperties.$isSiteEmrEnabled = isSiteEmrEnabled || false
  app.provide('isSiteEmrEnabled', isSiteEmrEnabled || false)

  if (from.path.includes('flight-board')) {
    store.dispatch('spinnerModule/hideSpinner', null, { root: true })
  }
  const allowGuests = to.matched.some((route) => route.meta && route.meta.allowGuests)
  const thereIsToken = storage.getFieldSessionStorage('user', 'token')
  if (!allowGuests && !thereIsToken) {
    storage.removeFieldSessionStorage()
    next({ path: '/' })
    return
  }
  const { allowCreateStoreTickets, allowCreateRetrieveTickets } = isSiteEmrEnabled
  if (to.path === '/select-procedure' && !(allowCreateStoreTickets || allowCreateRetrieveTickets)) {
    next({ path: '/flight-board' })
    return
  }

  // Disallow non admin users with no bulk import access to be routed to Bulk Import views.
  const { permissions, userType } = storage.getFieldSessionStorage('user')
  if (
    (to.path.includes('reformatting') || to.path.includes('specimens-bulk-import')) &&
    !(permissions.includes('BULK_SPECIMEN_UPLOAD') && userType === USER_ADMINISTRATOR)
  ) {
    next({ path: '/flight-board' })
    return
  }
  next()
})

export default router
