import { ref, onMounted, Ref } from 'vue'
import { useStore } from 'vuex'
import { Destination, DestinationOption } from '@/types'
import * as ss from '@/config/session-storage-help'

export function useGetDestination() {
  const store = useStore()
  const destinationOptions: Ref<DestinationOption[]> = ref([])
  const selectedDestination: Ref<string> = ref('')
  const getDestinationOptions = async ({ showDefault }): Promise<void> => {
    try {
      store.dispatch('spinnerModule/displaySpinner', 'Loading destinations')
      const result: Destination[] = ss.getFieldSessionStorage('containersBySite')
      store.dispatch('spinnerModule/hideSpinner')
      const mappedDestinations = result.map((destination) => ({
        value: destination.containerId,
        label: `${destination.robotName}${destination.defaultRobot && showDefault ? ' (Default)' : ''}` || '',
        robotLayoutTypeId: destination.robotLayoutTypeId,
        defaultRobot: destination.defaultRobot || false
      }))
      destinationOptions.value = mappedDestinations
      store.dispatch('ticketsModule/fetchDestinationOptions')
      setDefaultDestination(mappedDestinations)
    } catch (error) {
      console.error('Error getting destinations:', error)
    }
  }

  const setDefaultDestination = (mappedDestinations): void => {
    const defaultDestination = mappedDestinations.find((destination) => destination.defaultRobot)
    if (defaultDestination) {
      onSelectDestination(defaultDestination.value)
    }
  }

  const onSelectDestination = (value): void => {
    if(selectedDestination.value === value) return
    selectedDestination.value = value
    store.dispatch('ticketsModule/setSelectedDestination', value)
  }

  const resetDestination = () => {
    selectedDestination.value = ''
    store.dispatch('ticketsModule/setSelectedDestination', '')
  }

  onMounted(() => {
    getDestinationOptions({ showDefault: true })
  })

  return {
    getDestinationOptions,
    destinationOptions,
    onSelectDestination,
    selectedDestination,
    resetDestination
  }
}
