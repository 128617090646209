<template>
  <div
    data-test="external-clinics-selector-modal"
    class="modal-view flex items-center justify-center fixed left-0 top-0 w-screen h-screen bg-black bg-opacity-50"
  >
    <div
      v-if="!isExternalClinicForm"
      data-test="external-clinics-selector-main-window"
      class="bg-white p-6 w-5/12 rounded-md text-black"
    >
      <ActionBar data-test="customize-beacons__action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <dynamic-title titleType="h3-blue" floatTo="left"
            >{{ isShippingSource ? 'Shipping Source' : 'Destination' }}:</dynamic-title
          >
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnText=""
            btnType="button"
            btnStyle="close-button-blue"
            showIcon
            fontAwesomeIconClass="times-circle"
            @click="closeClinicSelector"
          />
        </template>
      </ActionBar>
      <input-field
        :inputPlaceholder="`Enter a ${
          isShippingSource ? 'source' : 'destination'
        } name or its address`"
        v-model.trim="filter"
        :inputValue="filter"
        inputName="source"
        borderColor="tmrw-blue"
        placeholderColor="text-tmrw-blue"
        addPaddingTop
        addPaddingBottom
        addMarginTop
        addMarginBottom
      />
      <div class="w-full bg-tmrw-green-light my-4 rounded-md p-2 p-2 mt-5 h-64 overflow-y-scroll">
        <ul data-test="import-ticket-external-clinics-selector" v-if="clinicsList.length">
          <li
            @click="selectExternalClinicFromList(clinic, index)"
            class="p-2 leading-normal cursor-pointer"
            v-for="(clinic, index) in clinicsList"
            :key="clinic.id"
          >
            <div class="flex">
              <div class="w-1/12">
                <i v-if="index !== activeClinicIndex" class="far fa-circle" />
                <i v-if="index === activeClinicIndex" class="fa fa-check-circle" />
              </div>
              <div class="w-11/12">
                <p class="font-bold font-inconsolata text-tmrw-blue-dark">
                  {{ clinic.name }}
                </p>
                <p class="font-inconsolata text-tmrw-blue-dark">
                  {{ getClinicAddress(clinic) }}
                </p>
                <p class="font-inconsolata text-tmrw-blue-dark">
                  {{ getClinicStateCountry(clinic) }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div v-else class="grid text-center justify-center block mt-20">
          <p class="text-tmrw-blue-dark font-bold">Results not found</p>
          <p class="text-tmrw-blue-dark">Would you like to</p>
          <button-dynamic
            btnType="button"
            btnStyle="transparent-blue-no-border"
            :btnText="`add a ${isShippingSource ? 'shipping' : 'destination'} source?`"
            isTextUnderlined
            @click="showExternalClinicForm"
          />
        </div>
      </div>
      <div class="w-full float-right flex justify-between mt-4">
        <button-dynamic
          btnType="button"
          btnText="Add New"
          btnStyle="transparent-blue"
          data-test="external-clinic-selector__add-btn"
          showIcon
          fontAwesomeIconClass="plus-circle"
          @click="showExternalClinicForm()"
        />
        <section>
          <button-dynamic
            data-test="done-button"
            btnType="button"
            btnText="Done"
            btnStyle="primary"
            showIcon
            fontAwesomeIconClass="check-circle"
            :isDisabled="!activeClinicIndex && !clinicFromList"
            @click="selectClinic"
          />
          <button-dynamic
            btnType="button"
            btnText="Cancel"
            btnStyle="transparent-blue"
            addMarginRight
            @click="closeClinicSelector"
          />
        </section>
      </div>
    </div>
    <div v-else class="bg-white p-6 w-5/12 rounded-md text-black">
      <ActionBar data-test="customize-beacons__action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <dynamic-title titleType="h3-blue" floatTo="left"
            >{{ isShippingSource ? `Shipping Source` : `Destination` }}:</dynamic-title
          >
        </template>
        <template v-slot:right-side>
          <button-dynamic
            btnText=""
            btnType="button"
            btnStyle="close-button-blue"
            showIcon
            fontAwesomeIconClass="times-circle"
            @click="closeClinicSelector"
          />
        </template>
      </ActionBar>
      <div class="mt-5">
        <p class="text-lg">Add Entry</p>
        <google-autocomplete
          id="place_autocomplete"
          class="w-full bg-white pb-4 mt-5"
          :placeholder="`Please enter a ${isShippingSource ? 'source' : 'destination'} name`"
          placeType="establishment"
          @selectedPlace="setSelectedPlace"
          @setClinicName="setClinicName"
          :clinicName="selectedClinicData"
          :maxlength="maxlength"
        />
        <google-autocomplete
          id="address_autocomplete"
          class="w-full bg-white pb-4 mt-5"
          :placeholder="`Please enter a ${isShippingSource ? 'source' : 'destination'} address`"
          placeType="address"
          @selectedPlace="setSelectedPlace"
          :address="selectedClinicData"
          :maxlength="maxlength"
        />
        <p
          data-test="login-form-group-alert"
          class="text-tmrw-error mt-4"
          v-if="this.showErrorEntry"
        >
          <i class="fas fa-exclamation-circle" />
          This address is not correct. Please check it and validate it again.
        </p>
        <div v-if="selectedClinicData" class="mt-5">
          <div class="flex">
            <div class="w-6/12 mr-5">
              <p class="text-lg mb-2">City</p>
              <DataField
                :value="selectedClinicData.city || 'Missing info'"
                :edit="false"
                alignContentToLeft
              />
            </div>
            <div class="w-6/12">
              <p class="text-lg mb-2">Zip</p>
              <DataField
                :value="selectedClinicData.zip || 'Missing info'"
                :edit="false"
                alignContentToLeft
              />
            </div>
          </div>
          <div class="flex mt-5">
            <div class="w-6/12 mr-5">
              <p class="text-lg mb-2">State</p>
              <DataField
                :value="selectedClinicData.state || 'Missing info'"
                :edit="false"
                alignLeft
                alignContentToLeft
              />
            </div>
            <div class="w-6/12">
              <p class="text-lg mb-2">Country</p>
              <DataField
                :value="selectedClinicData.country || 'Missing info'"
                :edit="false"
                alignContentToLeft
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <button-dynamic
          btnType="button"
          btnText="Add"
          btnStyle="primary"
          data-test="external-clinic-selector__add-btn"
          showIcon
          fontAwesomeIconClass="plus-circle"
          :isDisabled="!isDataValid"
          @click="selectExternalClinicFromGoogle()"
        />
        <button-dynamic
          btnType="button"
          btnText="Cancel"
          btnStyle="transparent-blue"
          addMarginRight
          @click="backToClinicsList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoogleAutocomplete from '@/components/GoogleAutocomplete/GoogleAutocomplete.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DataField from '@/components/EditableFields/DataField.vue'
import InputField from '@/components/InputField/InputField.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'

export default {
  name: 'external-clinic-selector',
  data() {
    return {
      isExternalClinicForm: false,
      selectedClinicData: null,
      clinicFromList: null,
      filter: '',
      clinics: [],
      isDataValid: false,
      showErrorEntry: false,
      activeClinicIndex: null
    }
  },
  emits: ['closeClinicSelector', 'selectedShippingSource'],
  components: {
    ButtonDynamic,
    GoogleAutocomplete,
    DataField,
    DynamicTitle,
    InputField,
    ActionBar
  },
  props: {
    open: {
      type: Boolean
    },
    containerClasses: {
      type: Array,
      default: () => []
    },
    isShippingSource: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions('patientsModule', [
      'setNewClinicName',
      'setNewClinicAddress',
      'createExternalClinic',
      'getExternalClinics'
    ]),
    setSelectedPlace(placeData) {
      this.selectedClinicData = placeData
    },
    closeClinicSelector() {
      this.$emit('closeClinicSelector')
    },
    selectExternalClinicFromList(clinicData, index) {
      this.clinicFromList = clinicData
      this.activeClinicIndex = index
    },
    selectClinic() {
      this.$emit('selectedShippingSource', this.clinicFromList)
      this.$emit('closeClinicSelector')
    },
    async selectExternalClinicFromGoogle() {
      await this.createExternalClinic(this.selectedClinicData)
      this.$emit('selectedShippingSource', this.createdClinic)
      this.$emit('closeClinicSelector')
    },
    showExternalClinicForm() {
      this.isExternalClinicForm = true
    },
    backToClinicsList() {
      this.selectedClinicData = null
      this.showErrorEntry = false
      this.filter = ''
      this.isExternalClinicForm = false
    },
    setClinicName(clinicName) {
      this.selectedClinicData = { name: clinicName }
    },
    getClinicAddress(clinic) {
      return `${[clinic.streetAddress, clinic.city].join(', ')},`
    },
    getClinicStateCountry(clinic) {
      return [clinic.state, clinic.zip, clinic.country].join(', ')
    }
  },
  watch: {
    selectedClinicData() {
      if (this.selectedClinicData) {
        const keys = Object.values(this.selectedClinicData)

        if (keys.includes(undefined)) {
          this.isDataValid = false
          this.showErrorEntry = true
        } else {
          this.isDataValid = true
          this.showErrorEntry = false
        }
      } else {
        this.isDataValid = false
      }
    }
  },
  computed: {
    ...mapGetters('patientsModule', [
      'newClinicName',
      'newClinicAddress',
      'externalClinics',
      'createdClinic'
    ]),
    clinicsList() {
      const search = this.filter.toLowerCase().trim()
      if (!search) return this.clinics
      return this.clinics.filter(
        (c) =>
          c.name.toLowerCase().includes(search) || c.streetAddress.toLowerCase().includes(search)
      )
    },

    streetAddressData() {
      return this.selectedClinicData ? this.selectedClinicData.streetAddress : null
    }
  },
  async created() {
    try {
      await this.getExternalClinics()
      this.clinics = this.externalClinics
    } catch {
      this.clinics = []
    }
  }
}
</script>

<style lang="scss">
.pac-container:after {
  // Remove Powered by Google legend
  background-image: none !important;
  height: 0px;
}
</style>
