<template>
  <MainContainer data-test="inventory-update" :gridSlots="'3'">
    <top-header>{{ headerMessage }}</top-header>
    <ActionBar data-test="cancel-ticket__action-bar" cols-distribution="9/3">
      <template v-slot:left-side>
        <dynamic-title title-type="h2" float-to="left">
          Please update {{ CryoBeacon }} inventory to reflect changes
        </dynamic-title>
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="isEditingTicket"
          class="inventory-update__btn-cancel"
          btnText="Cancel"
          btnType="button"
          btnStyle="secondary"
          add-margin-right
          @click="handleCancelEdit"
        />
        <button-dynamic
          v-else
          class="inventory-update__btn-cancel"
          btnText="Cancel"
          btnType="button"
          btnStyle="secondary"
          add-margin-right
          @click="handleCancel"
        />
        <button-dynamic
          class="inventory-update__btn-update"
          btnText="Update Ticket"
          btnType="button"
          btnStyle="primary"
          fontAwesomeIconClass="check-circle"
          showIcon
          :is-disabled="updateDisabled"
          @click="handleUpdate"
        />
      </template>
    </ActionBar>
    <section>
      <h1
        data-test="inventory-update-subtitle"
        class="text-tmrw-gray text-2xl font-exo font-semibold mb-4"
      >
        {{ selectedSpecimensTableTitle }}
      </h1>
      <InventorySpecimenTables
        title="selected-specimen-tables"
        :ticketDetail="ticketDetail"
        :embryoSpecimens="selectedInventoryModelEmbryos"
        :embryoSpecimenHeaders="selectedEmbryoSpecimenHeaders"
        :eggSpecimenHeaders="selectedEggSpecimenHeaders"
        :eggSpecimens="selectedInventoryModelEggs"
        @onEmbryosInput="handleSelectedEmbryosInput"
        @onEggsInput="handleSelectedEggsInput"
      />
      <div class="flex justify-end w-full font-inconsolata text-tmrw-green-light mt-4">
        <p>{{ newCryoDevicesAmountMessage }}</p>
      </div>
      <div class="mt-3" v-if="hasExistingInventorySpecimens">
        <h1 class="text-tmrw-gray text-2xl font-exo font-semibold mb-4">
          Existing {{ CryoDevices }}
        </h1>
        <InventorySpecimenTables
          title="existing-specimen-tables"
          :embryoSpecimenHeaders="existingEmbryoSpecimenHeaders"
          :embryoSpecimens="existingInventoryModelEmbryos"
          :eggSpecimenHeaders="existingEggSpecimenHeaders"
          :eggSpecimens="existingInventoryModelEggs"
          @onEmbryosInput="handleExistingEmbryosInput"
          @onEggsInput="handleExistingEggsInput"
        />
        <span class="flex justify-end w-full font-inconsolata text-tmrw-green-light mt-4">
          <p>{{ existingCryoDevicesAmountMessage }}</p>
        </span>
      </div>
      <div v-if="isAbleToAddNotes && !isLoading" class="mb-7">
        <h1 class="text-tmrw-gray text-2xl font-exo font-semibold mb-4">Notes:</h1>
        <div
          data-testid="inventory-notes"
          class="flex text-xl text-white leading-6 bg-white w-full px-4 pb-7 pt-5 rounded-lg flex-col"
        >
          <input
            data-testid="inventory-notes-input"
            class="mr-1 px-4 pt-4 pb-2 text-tmrw-blue-light w-full border-solid border-b-2 border-tmrw-blue font-exo focus:outline-none placeholder-blue-900"
            name="inventory-notes"
            placeholder="Add notes"
            :maxlength="250"
            v-model="notes"
          />
        </div>
      </div>
      <h1 v-if="!isLoading" class="text-tmrw-gray text-2xl font-exo font-semibold mb-4">
        Location
      </h1>
      <div v-if="!isLoading" class="grid grid-flow-col w-full gap-x-4 bg-white rounded-xl p-2">
        <div class="col-span-2">
          <CustomSelect
            data-testid="details__action-bar__destination"
            dataTest="details__action-bar__destination"
            class="bg-transparent p-2 rounded-lg w-full mr-10"
            customText="STORE TO"
            :options="destinationOptions"
            :modelValue="selectedUnitId"
            defaultOptionLabel="Default"
            hasLargePadding
            noTemporaryContainers
            @update:modelValue="onChangeDestination"
          />
        </div>
        <div
          v-if="selectedUnitLayoutId === layoutWave"
          class="col-span-9 flex justify-center items-center pr-2"
        >
          <InputField
            data-test="inventory-update__cryobeacon_location"
            class="w-full pt-6 pb-0.5"
            :class="[formErrors && formErrorMsg.locationDetails ? 'pt-8' : 'pt-6']"
            :inputPlaceholder="`${CryoBeacon} Location`"
            v-model.trim="v$.form.locationDetails.$model"
            :inputValue="checkLocationDetails()"
            maxLength="40"
            placeholderColor="tmrw-blue-dark"
            labelColor="tmrw-blue-dark"
            borderColor="tmrw-blue"
            :hasError="
              formErrors && hasRemainingSpecimensInBeacon() && formErrorMsg.locationDetails
            "
            addPaddingBottom
            hasFontInconsolata
            addPaddingTop
            :is-disabled="isLocationDisabled"
            @change="updateLocationDetails"
          />
        </div>
        <div v-else class="col-span-9 flex justify-center items-center pr-2">&nbsp;</div>
      </div>
    </section>
    <DiscardBeaconModal
      title="discard-beacon-modal"
      :shouldShowModal="showDiscardBeaconModal"
      :discardBeaconMessage="getDiscardBeaconModalMessage()"
      @confirm-clicked="finishUpdate"
      @cancel-clicked="handleModalCancel"
    />
    <ReadOnlyTicketModal
      title="read-only-ticket-modal"
      :shouldShowModal="showReadOnlyMessage"
      @ok-clicked="handleCloseReadOnlyMessage"
    />
    <ModalScan
      :is-modal-open="showScanModal"
      :cryo-devices="notPresentModels"
      :ticket="ticketDetail"
      @close="handleModalCancel"
      @continue="handleScanContinue"
    />
    <loading-ui modal v-if="isLoaderVisible" :message="loaderMessage" />
  </MainContainer>
</template>

<script setup>
import toast from '@/config/toast'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import newInventoryUpdateActionsHelper, {
  getHeaders,
  getDonationToPatientHeaders,
  getDiscardBeaconModalMessage
} from '@/helpers/newInventoryUpdateActions'
import {
  TICKET_COMPLETE,
  PROCEDURE_LIST,
  CRYOBEACON,
  CRYODEVICE,
  CRYODEVICES,
  PROCEDURE_TYPE_DONATION_TO_PATIENT,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_EGG,
  PROCEDURE_TYPE_EXPORT,
  PROCEDURE_TYPE_THAW,
  PROCEDURE_MISSING_CRYOBEACON,
  MISSING_CRYOBEACON,
  EXISTING_BEACON_TICKET_SUCCESS_MESSAGE,
  TITLE_CASE_PROCEDURE_TYPES,
  INVENTORY_ACTION_ADDED,
  LAYOUT_WAVE
} from '@/constants'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DiscardBeaconModal from '@/components/DiscardBeaconModal/DiscardBeaconModal.vue'
import ReadOnlyTicketModal from '@/components/ReadOnlyTicketModal/ReadOnlyTicketModal.vue'
import ModalScan from '@/components/ModalScan/ModalScan.vue'
import InventorySpecimenTables from '@/views/InventorySpecimenTables/InventorySpecimenTables.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import { useGetDestination, useValidateDestination } from '@/composables'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import InputField from '@/components/InputField/InputField.vue'
import { requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { validateFields } from '@/helpers/formsFieldsValidation/index'
import { getCurrentCryoBeaconLocation } from '@/helpers/cryoBeaconLocation/index'
import * as ss from '@/config/session-storage-help'

import useActions from '@/composables/useActions'
import useGetters from '@/composables/useGetters'
import { computed, inject, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
  patientId: {
    type: String
  },
  ticketId: {
    type: String
  }
})
const { destinationOptions, onSelectDestination, resetDestination } = useGetDestination()
const { validateUnitSpace, setUnitSpaceValidationFailed } = useValidateDestination()

const { nonTmrwLocationDetails } = inject('isSiteEmrEnabled')

const isLocationDisabled = ref(!nonTmrwLocationDetails?.allowEdit)

const isSpaceAvailableInSelectedUnit = ref(false)

const state = reactive({
  form: {
    locationDetails: ''
  }
})

const rules = computed(() => ({
  form: {
    locationDetails: {
      requiredDetails: requiredIf(!!nonTmrwLocationDetails?.isRequired),
      lettersNumberBlankSpaces: (value) => /^[a-zA-Z0-9)(,.\- [\]{}]*$/i.test(value)
    }
  }
}))
const v$ = useVuelidate(rules, state)

const existingInventoryModel = ref({
  sort: {
    orderBy: 'cryodeviceBarcode',
    direction: 'asc'
  },
  options: []
})
const selectedInventoryModelEmbryos = ref({
  sort: {
    orderBy: 'cryodeviceBarcode',
    direction: 'asc'
  },
  options: []
})
const selectedInventoryModelEggs = ref({
  sort: {
    orderBy: 'cryodeviceBarcode',
    direction: 'asc'
  },
  options: []
})
const existingInventoryModelEmbryos = ref({
  sort: {
    orderBy: 'cryodeviceBarcode',
    direction: 'asc'
  },
  options: []
})
const existingInventoryModelEggs = ref({
  sort: {
    orderBy: 'cryodeviceBarcode',
    direction: 'asc'
  },
  options: []
})
const selectedEmbryoSpecimenHeaders = ref([])
const selectedEggSpecimenHeaders = ref([])
const existingEmbryoSpecimenHeaders = ref([])
const existingEggSpecimenHeaders = ref([])
const showDiscardBeaconModal = ref(false)
const showReadOnlyMessage = ref(false)
const showScanModal = ref(false)
const isEditingTicket = ref(false)
const CryoBeacon = ref(CRYOBEACON)
const CryoDevices = ref(CRYODEVICES)
const isLoading = ref(false)
const notes = ref('')
const loaderMessage = ref('Loading...')
const isLoaderVisible = ref(false)
const selectedUnitLayoutId = ref(0)

const formErrorMsg = ref({
  locationDetails: null
})
const selectedUnitId = ref(0)
const layoutWave = ref(LAYOUT_WAVE)
const cryoBeaconLocation = ref(null)

/*
    ...mapGetters('calendarModule', ['ticketToUpdate']),
    ...mapGetters('selectedTicketsModule', ['ticketDetail']),
    ...mapGetters('authModule', ['loggedUserInfo']),
*/

const { ticketToUpdate } = useGetters('calendarModule')
const { selectedTickets, ticketDetail } = useGetters('selectedTicketsModule')
const { loggedUserInfo } = useGetters('authModule')

const mergedInventoryModels = computed(() => {
  return [...selectedSpecimens.value, ...existingSpecimens.value]
})
const notPresentModels = computed(() => {
  return mergedInventoryModels.value.filter(({ inventoryAction }) =>
    newInventoryUpdateActionsHelper.notPresentValidActions.includes(inventoryAction)
  )
})
const selectedSpecimens = computed(() => {
  return [
    ...selectedInventoryModelEmbryos.value.options,
    ...selectedInventoryModelEggs.value.options
  ]
})
const existingSpecimens = computed(() => {
  return [
    ...existingInventoryModelEmbryos.value.options,
    ...existingInventoryModelEggs.value.options
  ]
})
const newCryoDevicesAmountMessage = computed(() => {
  const embryoCryoDevicesCount = selectedInventoryModelEmbryos.value.options.length
  const eggCryoDevicesCount = selectedInventoryModelEggs.value.options.length
  const cryoDevicesCount = embryoCryoDevicesCount + eggCryoDevicesCount
  return `${cryoDevicesCount} ${CRYODEVICE}${cryoDevicesCount > 1 ? 's' : ''}`
})

const isProcedureTypeDonationToPatient = computed(() => {
  return ticketDetail.value.procedureType === PROCEDURE_TYPE_DONATION_TO_PATIENT
})
const isProcedureTypeExport = computed(() => {
  return ticketDetail.value.procedureType === PROCEDURE_TYPE_EXPORT
})
const isProcedureTypeThaw = computed(() => {
  return ticketDetail.value.procedureType === PROCEDURE_TYPE_THAW
})

const hasSelectedInventoryModelEmbryos = computed(() => {
  return Boolean(selectedInventoryModelEmbryos.value.options.length)
})
const hasSelectedInventoryModelEggs = computed(() => {
  return Boolean(selectedInventoryModelEggs.value.options.length)
})
const hasExistingInventoryModelEmbryos = computed(() => {
  return Boolean(existingInventoryModelEmbryos.value.options.length)
})
const hasExistingInventoryModelEggs = computed(() => {
  return Boolean(existingInventoryModelEggs.value.options.length)
})
const hasExistingInventorySpecimens = computed(() => {
  return hasExistingInventoryModelEmbryos.value || hasExistingInventoryModelEggs.value
})
const existingCryoDevicesAmountMessage = computed(() => {
  const embryoCryoDevicesCount = existingInventoryModelEmbryos.value.options.length
  const eggCryoDevicesCount = existingInventoryModelEggs.value.options.length
  const cryoDevicesCount = embryoCryoDevicesCount + eggCryoDevicesCount
  return `${cryoDevicesCount} ${CRYODEVICE}${cryoDevicesCount > 1 ? 's' : ''}`
})
const isDisplayingAllSelectedSpecimensTables = computed(() => {
  return (
    (hasSelectedInventoryModelEmbryos.value && hasSelectedInventoryModelEggs.value) ||
    (hasExistingInventoryModelEmbryos.value && hasExistingInventoryModelEggs.value)
  )
})
const selectedSpecimensTableTitle = computed(() => {
  if (isProcedureTypeExport.value || isProcedureTypeThaw.value) {
    return `Selected ${CRYODEVICES}`
  }
  if (ticketDetail.value?.procedureName === MISSING_CRYOBEACON) {
    return null
  }
  return `New ${CRYODEVICES}`
})
const isAbleToAddNotes = computed(() => {
  switch (ticketDetail.value.procedure) {
    case PROCEDURE_MISSING_CRYOBEACON:
      return true
    default:
      return false
  }
})
const headerMessage = computed(() => {
  const procedureTitleCase = TITLE_CASE_PROCEDURE_TYPES[ticketDetail.value?.procedureType]
  if (procedureTitleCase) {
    return `You're accounting for the specimens of this ${procedureTitleCase} ticket`
  }
  return ''
})
const formErrors = computed(() => {
  const requiredFields = ['locationDetails']
  const { form } = v$.value
  return validateFields(form, requiredFields, formErrorMsg.value)
})

const updateDisabled = computed(() => {
  return (
    v$.value.$invalid &&
    selectedUnitLayoutId.value === layoutWave.value &&
    hasRemainingSpecimensInBeacon()
  )
})
/*
    getDiscardBeaconModalMessage,
    ...mapActions('selectedTicketsModule', [
      'fetchTicketsDetail',
      'updateTickets'
    ]),
    ...mapActions('calendarModule', [
      'setCalendarMinDate',
      'setCalendarMaxDate',
      'setPerformedOnCalendarDate',
      'setTicketToUpdate'
    ]),
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    */
const { fetchTicketsDetail, updateTickets, setSelectedTickets } =
  useActions('selectedTicketsModule')
const { setCalendarMinDate, setCalendarMaxDate, setPerformedOnCalendarDate, setTicketToUpdate } =
  useActions('calendarModule')
const { displaySpinner, hideSpinner } = useActions('spinnerModule')

const checkLocationDetails = () => {
  const startsWithRackOrPosition = /^(rack|position)/i.test(state.form.locationDetails)
  return state.form.locationDetails &&
    selectedUnitLayoutId.value === layoutWave.value &&
    !startsWithRackOrPosition
    ? state.form.locationDetails
    : ''
}
const hasRemainingSpecimensInBeacon = () => {
  const remainingSpecimens = mergedInventoryModels.value.some((specimen) => {
    return (
      specimen.inventoryAction === newInventoryUpdateActionsHelper.remainsInInventoryAction ||
      specimen.inventoryAction === INVENTORY_ACTION_ADDED
    )
  })
  return remainingSpecimens
}
const setInventoryModels = (newTicketDetail) => {
  const specimensInTicket = newTicketDetail.specimens.filter(
    (specimen) => specimen.inSelectedTicket
  )
  const embryoSpecimensInSelectedTicket = specimensInTicket.filter(
    ({ specimenType }) => specimenType === SPECIMEN_TYPE_EMBRYO
  )
  const eggSpecimensInSelectedTicket = specimensInTicket.filter(
    ({ specimenType }) => specimenType === SPECIMEN_TYPE_EGG
  )

  selectedInventoryModelEmbryos.value = {
    ...selectedInventoryModelEmbryos.value,
    options: embryoSpecimensInSelectedTicket
  }
  selectedInventoryModelEggs.value = {
    ...selectedInventoryModelEggs.value,
    options: eggSpecimensInSelectedTicket
  }

  const specimensNotInTicket = newTicketDetail.specimens.filter(
    (specimen) => !specimen.inSelectedTicket
  )
  const embryoSpecimensNotInSelectedTicket = specimensNotInTicket.filter(
    ({ specimenType }) => specimenType === SPECIMEN_TYPE_EMBRYO
  )
  const eggSpecimensNotInSelectedTicket = specimensNotInTicket.filter(
    ({ specimenType }) => specimenType === SPECIMEN_TYPE_EGG
  )

  existingInventoryModelEmbryos.value = {
    ...existingInventoryModel.value,
    options: embryoSpecimensNotInSelectedTicket
  }
  existingInventoryModelEggs.value = {
    ...existingInventoryModel.value,
    options: eggSpecimensNotInSelectedTicket
  }
}
const setSpecimenTableHeaders = () => {
  setSelectedEmbryoSpecimenHeaders()
  setExistingEmbryoSpecimenHeaders()
  setSelectedEggSpecimenHeaders()
  setExistingEggSpecimenHeaders()
}
const setSelectedEmbryoSpecimenHeaders = () => {
  selectedEmbryoSpecimenHeaders.value = getEmbryoSpecimenHeaders(true)
}
const setExistingEmbryoSpecimenHeaders = () => {
  existingEmbryoSpecimenHeaders.value = getEmbryoSpecimenHeaders(false)
}
const setSelectedEggSpecimenHeaders = () => {
  selectedEggSpecimenHeaders.value = getEggSpecimenHeaders(true)
}
const setExistingEggSpecimenHeaders = () => {
  existingEggSpecimenHeaders.value = getEggSpecimenHeaders(false)
}
const getEmbryoSpecimenHeaders = (isInSelectedTicket) => {
  switch (true) {
    case isProcedureTypeDonationToPatient.value:
      return getDonationToPatientHeaders({
        specimenType: SPECIMEN_TYPE_EMBRYO,
        isInSelectedTicket,
        state: ticketDetail.value.state
      })
    default:
      return getHeaders({
        procedureType: ticketDetail.value.procedureType,
        specimenType: SPECIMEN_TYPE_EMBRYO
      })
  }
}
const getEggSpecimenHeaders = (isInSelectedTicket) => {
  switch (true) {
    case isProcedureTypeDonationToPatient.value:
      return getDonationToPatientHeaders({
        specimenType: SPECIMEN_TYPE_EGG,
        isInSelectedTicket,
        state: ticketDetail.value.state
      })
    default:
      return getHeaders({
        procedureType: ticketDetail.value.procedureType,
        specimenType: SPECIMEN_TYPE_EGG,
        useMatchingEggHeaders: isDisplayingAllSelectedSpecimensTables.value
      })
  }
}

const handleCloseReadOnlyMessage = () => {
  showReadOnlyMessage.value = false
}
const handleCancel = () => {
  router.push('/flight-board')
}
const handleCancelEdit = () => {
  router.push('/selected-tickets')
}
const handleModalCancel = () => {
  showScanModal.value = false
  showDiscardBeaconModal.value = false
}
const finishUpdate = async () => {
  showDiscardBeaconModal.value = false
  showScanModal.value = false
  sendUpdateTicket()
}
const handleSelectedEmbryosInput = (value) => {
  selectedInventoryModelEmbryos.value = value
}
const handleSelectedEggsInput = (value) => {
  selectedInventoryModelEggs.value = value
}
const handleExistingEmbryosInput = (value) => {
  existingInventoryModelEmbryos.value = value
}
const handleExistingEggsInput = (value) => {
  existingInventoryModelEggs.value = value
}
const handleUpdate = async () => {
  isLoading.value = true
  v$.value.$touch()
  if (
    v$.value.$invalid &&
    selectedUnitLayoutId.value === LAYOUT_WAVE &&
    hasRemainingSpecimensInBeacon()
  ) {
    isLoading.value = false
    return
  }
  if (notPresentModels.value.length) {
    isLoading.value = false
    showScanModal.value = true
    return
  }
  continueDiscarded()
}
const sendUpdateTicket = async () => {
  isLoading.value = true
  displaySpinner('Updating ticket')
  const stateTicket = !hasRemainingSpecimensInBeacon() ? TICKET_COMPLETE : ticketDetail.value.state
  const { procedureName, ticketId, metadata } = ticketDetail.value
  const { userId } = loggedUserInfo.value
  const procedureValue = PROCEDURE_LIST[procedureName]
  const specimens = mergedInventoryModels.value
  const newTicketToUpdate = {
    ...ticketToUpdate.value,
    ticketId,
    userId,
    procedureName: procedureValue,
    state: stateTicket,
    specimens,
    metadata,
    unitId: selectedUnitId.value,
    ...(state.form.locationDetails !== '' &&
      selectedUnitLayoutId.value === LAYOUT_WAVE && { locationDetails: state.form.locationDetails })
  }

  try {
    await updateTickets({ tickets: [newTicketToUpdate], ticketId })
    const activeSlide = ss.getFieldSessionStorage('process', 'activeSlideSelectedTickets') || 1

    let newSelectedTickets = [...(selectedTickets.value || [])]
    if (newSelectedTickets[activeSlide - 1]) {
      newSelectedTickets[activeSlide - 1] = newTicketToUpdate
    } else {
      newSelectedTickets = [newTicketToUpdate]
    }
    setSelectedTickets(newSelectedTickets)
    setCalendarMinDate(null)
    setCalendarMaxDate(null)
    setPerformedOnCalendarDate(null)
    toast.success(EXISTING_BEACON_TICKET_SUCCESS_MESSAGE)
    setTicketToUpdate({})
    router.push('/selected-tickets')
  } catch (err) {
    toast.error({ title: err.message })
    // throw new Error(`Inventory Update Error - ${err}`)
  } finally {
    hideSpinner()
    isLoading.value = false
  }
}
const handleScanContinue = () => {
  showScanModal.value = false
  continueDiscarded()
}
const continueDiscarded = async () => {
  if (!hasRemainingSpecimensInBeacon()) {
    showDiscardBeaconModal.value = true
    isLoading.value = false
    return
  }
  await finishUpdate()
}
const onChangeDestination = async (value, showLoader = true) => {
  onSelectDestination(value)
  if (value) {
    const validationPayload = {
      screeningStatus: ticketDetail.value.screeningStatus,
      containerId: value
    }
    isLoaderVisible.value = showLoader
    const hasUnitAvailableSpace = await validateUnitSpace(validationPayload)
    const newSelectedUnitLayoutId = destinationOptions.value.find((destination) => {
      return destination.value === Number(value)
    })
    selectedUnitLayoutId.value = newSelectedUnitLayoutId?.robotLayoutTypeId || 0
    isSpaceAvailableInSelectedUnit.value = hasUnitAvailableSpace
    selectedUnitId.value = value
    isLoaderVisible.value = false
    state.form.locationDetails = ''
  } else {
    setUnitSpaceValidationFailed(false)
  }
}

const updateLocationDetails = () => {
  formErrors.value.locationDetails = null
  formErrorMsg.value.locationDetails = null
  state.form.locationDetails = v$.value.form.locationDetails.$model
}

onMounted(async () => {
  try {
    ss.removeFieldSessionStorage('selectedDestination')
    isLoading.value = true
    displaySpinner(loaderMessage.value)
    await fetchTicketsDetail([props.ticketId])
  } catch (err) {
    toast.error({ title: err.message })
  } finally {
    hideSpinner()
    isLoading.value = false
  }
})
watch(ticketDetail, (newTicketDetail) => {
  setInventoryModels(newTicketDetail)
  setSpecimenTableHeaders()
  cryoBeaconLocation.value = getCurrentCryoBeaconLocation(newTicketDetail.robotLocations)
  state.form.locationDetails = cryoBeaconLocation.value.locationDetails
  selectedUnitLayoutId.value = cryoBeaconLocation.value.robotLayoutTypeId
  selectedUnitId.value = cryoBeaconLocation.value.robotId
})
watch(notes, (newValue) => {
  const { metadata } = ticketDetail.value
  Object.assign(metadata, { notes: newValue })
})

watch(destinationOptions, (newDestinationOptions) => {
  setTimeout(async () => {
    // Reset Destination after destinationOptions are updated
    await resetDestination()
    // Set default destination
    await onSelectDestination(newDestinationOptions[0].value)
  }, 1000)
})
</script>
