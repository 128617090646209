import { capitalize } from '@/helpers/stringHelper'
import imageNegativeScreenStatus from '@/assets/images/screenstatus-negative.svg'
import imagePositiveScreenStatus from '@/assets/images/screenstatus-positive.svg'
import imageUnscreenedScreenStatus from '@/assets/images/screenstatus-unscreened.svg'

export const TMRW_ADDRESS = '250 Hudson St, Suite 102, New York, NY 10013'
export const DAY = 'day'
export const MONTH = 'month'
export const YEAR = 'year'
export const DATE_OF_BIRTH = 'Date of Birth'
export const DATE_AND_TIME = 'Date and Time'
export const EMBRYO_TOTAL = 'Embryo Total'
export const INVENTORY_UPDATE_REQUIRED = 'Required'
export const INVENTORY_UPDATE_DONE = 'Done'
export const INVENTORY_UPDATE_NOT_REQUIRED = 'Not Required'
export const MAX_PAGINATORS_COUNT_PER_VIEW = 15
export const INFECTIOUS_SCREENING_STATUS = 'Infectious Screening Status'
export const CURRENT_LOCATION = 'Location'
export const SCREENING_STATUS_POSITIVE = 'Positive'
export const SCREENING_STATUS_NEGATIVE = 'Negative'
export const SCREENING_STATUS_UNSCREENED = 'Unscreened'
export const AVAILABLE_FOR_USE = 'Available for use'
export const TICKET_STATUS_PENDING = 'Pending'
export const TICKET_STATUS_NONE = 'None'
export const UPDATE_INVENTORY = 'Update Inventory'
export const COMPLETE = 'Complete'
export const REVIEW_AUDIT = 'Review Audit'
export const SEND = 'Send'
export const REQUEST = 'Request'
export const FILTER_OVERDUE = 'overdue'
export const FILTER_TODAY = 'today'
export const FILTER_TODAY_LABEL = 'Today'
export const FILTER_TOMORROW = 'tomorrow'
export const FILTER_TOMORROW_LABEL = 'Tomorrow'
export const FILTER_NEXT_7_DAYS = 'next-7-days'
export const FILTER_NEXT_7_DAYS_LABEL = 'Next 7 Days'
export const FILTER_NEXT_30_DAYS = 'next-30-days'
export const FILTER_NEXT_30_DAYS_LABEL = 'Next 30 Days'
export const FILTER_30_DAYS = '30-days'
export const FILTER_30_DAYS_TODAY = '30-days-today'
export const FILTER_FROM_TODAY = 'from-today'
export const FILTER_30_DAYS_LABEL = '30+ Days'
export const TICKET_OPEN = 'Open'
export const TICKET_COMPLETED = 'Completed'
export const TICKET_INITIAL = 'Initial'
export const TICKET_OPEN_CAPITALS = 'OPEN'
export const TICKET_SCHEDULED = 'Scheduled'
export const TICKET_IN_PROGRESS = 'In Progress'
export const TICKET_COMPLETE = 'Complete'
export const TICKET_COMPLETE_CAPITALS = 'COMPLETE'
export const TICKET_CANCELLED = 'Cancelled'
export const TICKET_COMPLETE_READY = 'Complete Ready'
export const TICKET_DELAYED = 'Delayed'
export const TICKET_READY_TO_EXECUTE = 'Ready to Execute'
export const SPECIMEN_INVENTORY_ACTION_CONSUMED = 'Consumed'
export const SPECIMEN_INVENTORY_ACTION_DISCARDED = 'Discarded'
export const CRYOBEACON_DISCARDED = 'DISCARDED'
export const SPECIMEN_INVENTORY_ACTION_UNCHANGED = 'Unchanged'
export const SPECIMEN_INVENTORY_ACTION_EXPORTED = 'Exported'
export const SPECIMEN_INVENTORY_ACTION_DONATED = 'Donated'
export const SPECIMEN_INVENTORY_ACTION_PRESENT = 'Present'
export const SPECIMEN_INVENTORY_ACTION_NOT_PRESENT = 'Not Present'
export const SPECIMEN_BIOPSY_YES = 'Yes'
export const SPECIMEN_BIOPSY_YES_NORMAL = 'Yes - Normal'
export const SPECIMEN_BIOPSY_YES_ABNORMAL = 'Yes - Abnormal'
export const SPECIMEN_BIOPSY_NO = 'No'
export const SPECIMEN_TYPE_EMBRYO = 'Embryo'
export const SPECIMEN_TYPE_EMBRYOS = 'Embryos'
export const SPECIMEN_TYPE_EMBRYO_UPPERCASE = 'EMBRYO'
export const SPECIMEN_TYPE_EMBRYO_LOWERCASE = 'embryo'
export const SPECIMEN_TYPE_EGGS = 'Eggs'
export const SPECIMEN_TYPE_EGGS_UPPERCASE = 'EGGS'
export const SPECIMEN_TYPE_EGG = 'Egg'
export const SPECIMEN_TYPE_EGG_LOWERCASE = 'egg'
export const SPECIMEN_TYPE_EGG_UPPERCASE = 'EGG'
export const OOCYTE_TOTAL = 'Oocyte Total'
export const SPECIMEN_TYPE_OOCYTE = 'Oocyte'
export const SPECIMEN_TYPE_OOCYTE_LOWERCASE = 'oocyte'
export const SPECIMEN_TYPE_OOCYTE_UPPERCASE = 'OOCYTE'
export const SPECIMEN_TYPE_OOCYTES = 'Oocytes'
export const SPECIMEN_TYPE_SPERM = 'Sperm'
export const SPECIMEN_TYPE_SPERM_UPPERCASE = 'SPERM'
export const TOTAL_SPECIMEN_COUNT = 'Total Specimen Count'
export const TOTAL_SHARED = 'Total Shared'
export const CRYODEVICE_ID = 'cryodeviceBarcode'
export const SPECIMEN_TYPE = 'specimenType'
export const TYPE = 'Type'
export const EMBRYO_TYPE = 'embryoType'
export const EMBRYO_BIOPSY = 'embryo.biopsy'
export const REASON = 'reason'
export const ROBOT_LOCATIONS = 'robotLocations'
export const TICKET_ID = 'ticketId'
export const IS_IMPORT = 'isImport'
export const EMBRYO_BIOPSY_RESULT = 'embryo.biopsyResult'
export const TABLE_FIELDS_ALLOWS_EMPTY_VALUE = [IS_IMPORT, EMBRYO_BIOPSY_RESULT]
export const SPECIMEN_GRADE = 'grade'
export const EMBRYO_NUMBER = 'embryoNumber'
export const EMBRYO_COUNT = 'embryoCount'
export const OOCYTE_COUNT = 'oocyteCount'
export const CRYO_DATE = 'cryoDate'
export const CRYO_DATE_CAPITALS = 'CryoDate'
export const SEE_CRYO_DETAILS = 'seeCryoDetails'
export const BIOPSY = 'biopsy'
export const BIOPSY_CAPITALS = 'Biopsy'
export const BIOPSY_RESULT = 'biopsyResult'
export const BIOPSY_PERFORMED = 'biopsyPerformed'
export const MATURITY_CAPITALS = 'Maturity'
export const MATURITY_LEVEL = 'maturityLevel'
export const SPECIMEN_NOTES = 'specimenInfo'
export const FRONT_TIME_ZONE = 'UTC'
export const SPECIMEN_COUNT = 'specimenCount'
export const GRADE = 'grade'
export const GRADE_CAPITALS = 'Grade'
export const PROCEDURE = 'procedure'
export const PROCEDURE_TYPE_FREEZE = 'FREEZE'
export const PROCEDURE_TYPE_THAW = 'THAW'
export const PROCEDURE_TYPE_MOVE = 'MOVE'
export const PROCEDURE_TYPE_SINGLE_IMPORT = 'SINGLE_IMPORT'
export const PROCEDURE_TYPE_EXPORT = 'EXPORT'
export const PROCEDURE_TYPE_DONATION_TO_PATIENT = 'DONATION_TO_PATIENT'
export const PROCEDURE_TYPE_DONATION = 'DONATION'
export const PROCEDURE_TYPE_BATCH = 'Batch'
export const PROCEDURE_TYPE_BATCH_UPPERCASE = 'BATCH'
export const PROCEDURE_TYPE_BATCH_LOWERCASE = 'batch'
export const PROCEDURE_TYPE_REPLACE_CRYOBEACON = 'replace cryobeacon'
export const PROCEDURE_BATCH_RETRIEVE = 'Retrieve'
export const PROCEDURE_MISSING_CRYOBEACON = 'MISSING_CRYOBEACON'
export const PROCEDURE_EGG_FREEZE = 'EGG_FREEZE'
export const PROCEDURE_EGG_THAW = 'EGG_THAW'
export const PROCEDURE_EMBRYO_FREEZE = 'EMBRYO_FREEZE'
export const PROCEDURE_EMBRYO_SINGLE_IMPORT = 'EMBRYO_SINGLE_IMPORT'
export const PROCEDURE_EGG_SINGLE_IMPORT = 'EGG_SINGLE_IMPORT'
export const PROCEDURE_EMBRYO_THAW = 'EMBRYO_THAW'
export const PROCEDURE_EGG_MOVE = 'EGG_MOVE'
export const PROCEDURE_EMBRYO_MOVE = 'EMBRYO_MOVE'
export const PROCEDURE_EGG_REPLACE_BEACON = 'EGG_REPLACE_BEACON'
export const PROCEDURE_EMBRYO_REPLACE_BEACON = 'EMBRYO_REPLACE_BEACON'
export const PROCEDURE_EXPORT_CRYODEVICES = 'EXPORT_CRYODEVICES'
export const PROCEDURE_DONATION_TO_PATIENT = 'DONATION_TO_PATIENT'
export const MISSING_CRYOBEACON = 'Missing CryoBeacon'
export const EMR_SINGLE_IMPORT = 'EMR Single Import'
export const EMR_SOURCE_ID = 2
export const REPLACE_CRYOBEACON = 'REPLACE_BEACON'
export const EMR_FREEZE = 'EMR Freeze'
export const TEXT_BEACON = 'CryoBeacon'
export const INVENTORY_ACTION_ADDED = 'Added'
export const LOGGED_IN = 'loggedIn'
export const VALID_ORIGIN = 'validOrigin'
export const SYSTEM_CHECK = 'systemCheck'
export const BOX_VERIFIED = 'verified'
export const BOX_NOTVERIFIED = 'notVerified'
export const EGG_NUMBER = 'specimenCount'
export const SCREENING_STATUS = 'screeningStatus'
export const CREATE_PATIENT_ERROR_MESSAGE = 'Unable to add, a Patient with that ID already exists.'
export const LOADING_PATIENTS = 'Loading Patients'
export const PATIENT_DOCUMENTS = 'Patient Documents'
export const LOADING_SHARED_SPECIMENS = 'Loading Shared Specimens'
export const PATIENT_NOT_FOUND_ERROR = 'No patients found. Please check and try again.'
export const USER_CREATED_EMAIL_SENT = `The User has been created successfully.\n
  An email has been sent to activate the account.`
export const USER_ALERTS_SET = 'The User Email Alerts have been set.'
export const PATIENT_CREATED = 'The User has been created successfully.'
export const CRYODEVIDE_UP_TO = 8
export const API_LOADING = 'loading'
export const API_COMPLETED = 'completed'
export const API_ERROR = 'error'
export const API_COMPLETED_STATUS = 'completed'
export const API_LOADING_STATUS = 'loading'
export const API_ERROR_STATUS = 'error'
export const DEFAULT_SCREENING_STATUS = 3
export const APPLIED_TO = 'Applied To'
export const IN_PROGRESS = 'In Progress'
export const FREEZE = 'FREEZE'
export const FREEZE_TITLE_CASE = 'Freeze'
export const THAW_TITLE_CASE = 'Thaw'
export const THAW = 'THAW'
export const ADMIN_ROLES = ['Super Admin', 'Admin', 'Administrator']
export const ADMIN_ROLE = 'Admin'
export const ARCHIVED_ROLE = 'Archived'
export const EMBRYOLOGIST_ROLE = 'Embryologist'
export const USER_ADMINISTRATOR = 'Administrator'
export const RESULTS = 'results'
export const PROCEDURE_MOVE = 'move'
export const TMRW_LABEL_PRINTER = 'TMRW Label Printer'
export const TMRW_TICKET_PRINTER = 'TMRW Ticket Printer'
export const ZEBRA_LABEL_PRINTERS_ID = ['ZD620-300', 'ZD621-300', 'GX430']
export const ZEBRA_TICKET_PRINTERS_ID = ['ZD620-203', 'ZD621-203']
export const CALENDAR_DATE_FORMAT = 'YYYY-MM-DD hh:mm a'
export const DATE_FORMAT_FULL = 'YYYY-MM-DD hh:mm a'
export const DATE_FORMAT_SINGLE = 'YYYY-MM-DD'
export const TIME_FORMAT = 'hh:mm a'
export const TIME_FORMAT_24H = 'HH:mm'
export const PREVIEW_FORMAT = 'ddd D MMMM YYYY - hh:mm a'
export const PREVIEW_FORMAT_RANGE = 'ddd D MMMM YYYY'
export const MATURITY_LEVEL_GERMINAL_VESICLE = 'GV'
export const MATURITY_LEVEL_METAPHASE_I = 'MI'
export const MATURITY_LEVEL_METAPHASE_II = 'MII'
export const SINGLE_IMPORT_CONST = 'SINGLE_IMPORT'
export const SINGLE_IMPORT = 'Single Import'
export const HEADER_SCREENING_STATUS_MESSAGE = 'Update the Specimen Information'
export const BUBBLE_SCREENING_STATUS_EMBRYO_MESSAGE = `You can change the ${INFECTIOUS_SCREENING_STATUS}, the Biopsy Status, and add Biopsy Results`
export const BUBBLE_SCREENING_STATUS_OOCYTES_MESSAGE = `You can change the ${INFECTIOUS_SCREENING_STATUS}.`
export const INVENTORY_ITEM = 'Cryodevices'
export const INVENTORY_ITEM_SINGULAR = 'Cryodevice'
export const SPECIMEN_CAPITALIZED = 'Specimen'
export const SPECIMENS_CAPITALIZED = 'Specimens'
export const WRONG_MESSAGE_PATIENT_BULK = 'Something went wrong while uploading your file'
export const PROCESSING_PATIENT_BULK_IMPORT = 'Processing file... This may take a few minutes'
export const ERROR_MESSAGE_FILE_CSV = 'File extension error, the allowed extension is .csv'
export const ERROR_MESSAGE_CSV_FILE_UPLOAD =
  'File Upload error. Please verify your .csv file and try again.'
export const ENTER_VALID_DATE = 'Enter a valid date'
export const ENTER_VALID_MONTH = 'Enter a valid month value'
export const ENTER_VALID_DAY = 'Enter a valid day value'
export const ENTER_VALID_YEAR = 'Enter a valid year value'
export const DATE_CANNOT_BE_IN_FUTURE = 'Date cannot be in the future'
export const SCREENING_STATUS_UPDATE_ACKNOWLEDGE_TITLE =
  'Infectious Screening Status successfully updated!'
export const ACKNOWLEDGE_DONE = 'Done'
export const CHECKBOX = 'checkbox'
export const RADIO = 'radio'
export const CRYODEVICE = 'Cryodevice'
export const CRYODEVICES = 'Cryodevices'
export const ROBOT_TRANSFER_IN = 'IN'
export const ROBOT_TRANSFER_OUT = 'OUT'
export const VALIDATED_AT_SOURCE = 'VALIDATED_AT_SOURCE'
export const VALIDATED_AT_DESTINATION = 'VALIDATED_AT_DESTINATION'
export const ROBOT_TRANSFER_DONE = 'DONE'
export const ROBOT_TRANSFER_READY = 'READY'
export const ROBOT_TRANSFER_NOT_READY = 'NOT READY'
export const ROBOT_ID = 'robotId'
export const EMBRYO_FREEZE = 'Embryo Freeze'
export const OOCYTE_FREEZE = 'Oocyte Freeze'
export const OOCYTE_SINGLE_IMPORT = 'Oocyte Single Import'
export const LOADING_BATCHABLE_TICKETS = 'Loading batchable tickets'
export const WARNING = 'Warning'
export const REMOVE = 'Remove'
export const MAX_LABEL_LENGTH = 12
export const MAX_SITE_LENGTH = 15
export const TEMPORARY = 'Temporary'
export const TEMPORARY_STORAGE = `${TEMPORARY} Storage`
export const CRYOLABEL = 'CryoLabel'
export const MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE = 16

// Field Validation Messages
export const FIELD_IS_NUMERIC = 'This field can only contain numbers'
export const FIELD_IS_REQUIRED = 'This field is required'
export const INVALID_EMAIL_ADDRESS = 'Invalid email address'
export const FIELD_IS_LOWER_CASE = 'This field can only contain lower case letters'
export const SPECIAL_CHARACTER_NOT_ALLOWED = 'Special characters are not allowed'
export const ALPHA_SPACE = 'Only letters and spaces are allowed'
export const ALPHA_SPACE_DASH = 'Only letters, hyphens, and spaces are allowed'
export const ALPHANUM_SPACE_DASH = 'Only letters, numbers, hyphens, and spaces are allowed'
export const ALPHANUM_DASH_UNDER = 'Only letters, numbers, hyphens and underscores are allowed'
export const ALPHA_ONLY = 'Only letters are allowed'
export const INVALID_ZIP = 'Enter a valid zip code'
export const MAX_LENGTH_MESSAGE = (max) => `Reached the max length ${max}`
export const MIN_LENGTH_MESSAGE = (min) => `Please must provide at least ${min} characters`
export const MIN_LENGTH_INPUT_MESSAGE = (input, min) =>
  `${capitalize(input)} must have ${min} digits`

// ScanField
export const SCAN = 'Scan'
export const SCAN_BEACON = 'Scan CryoBeacon'
export const SCAN_BEACON_PLACEHOLDER = 'Enter a CryoBeacon'
export const SCAN_BEACON_TITLE = 'CryoBeacon ID'
export const SCAN_LABEL = 'Scan Label'
export const SCAN_AGAIN = 'Scan Again'
export const SCAN_CRYODEVICE_PLACEHOLDER = 'Enter a Cryodevice ID'
export const SCAN_CRYODEVICE_TITLE = 'Scan Specific Cryodevice ID'
export const SCAN_MODE_SCAN = 'scan'
export const SCAN_MODE_SHOW = 'show'
export const SCAN_VALID = 'VALID'

export const TRUE_STRING = 'true'
export const FALSE_STRING = 'false'

//cryogrids types
export const TOP = 'TOP'
export const SMALL = 'SMALL'
export const BOTTOM = 'BOTTOM'

export const PROCEDURE_LIST = {
  'Embryo Freeze': 'EMBRYO_FREEZE',
  'Embryo Thaw': 'EMBRYO_THAW',
  'Egg Freeze': 'EGG_FREEZE',
  'Egg Thaw': 'EGG_THAW',
  'Esperm Freeze': 'ESPERM_FREEZE',
  'Esperm Thaw': 'ESPERM_THAW',
  'Export Cryodevices': 'EXPORT_CRYODEVICES'
}

export const SCREENING_STATUSES = {
  1: 'SCREEN POSITIVE',
  2: 'SCREEN NEGATIVE',
  3: 'UNSCREENED'
}

export const SCREENING_STATUSES_API = {
  1: 'SCREEN_POSITIVE',
  2: 'SCREEN_NEGATIVE',
  3: 'UNSCREENED'
}

export const MATURITY_OPTIONS = [
  {
    value: MATURITY_LEVEL_GERMINAL_VESICLE,
    label: MATURITY_LEVEL_GERMINAL_VESICLE
  },
  {
    value: MATURITY_LEVEL_METAPHASE_I,
    label: MATURITY_LEVEL_METAPHASE_I
  },
  {
    value: MATURITY_LEVEL_METAPHASE_II,
    label: MATURITY_LEVEL_METAPHASE_II
  }
]
export const maturityLevelOptions = [
  {
    value: 'M1',
    label: 'Metaphase I'
  },
  {
    value: 'M2',
    label: 'Metaphase II'
  },
  {
    value: 'M3',
    label: 'Metaphase III'
  },
  {
    value: 'M4',
    label: 'Metaphase IV'
  },
  {
    value: 'M5',
    label: 'Metaphase V'
  }
]

export const MONTH_OPTIONS = [
  {
    label: 'JAN',
    value: '01'
  },
  {
    label: 'FEB',
    value: '02'
  },
  {
    label: 'MAR',
    value: '03'
  },
  {
    label: 'APR',
    value: '04'
  },
  {
    label: 'MAY',
    value: '05'
  },
  {
    label: 'JUN',
    value: '06'
  },
  {
    label: 'JUL',
    value: '07'
  },
  {
    label: 'AUG',
    value: '08'
  },
  {
    label: 'SEP',
    value: '09'
  },
  {
    label: 'OCT',
    value: '10'
  },
  {
    label: 'NOV',
    value: '11'
  },
  {
    label: 'DEC',
    value: '12'
  }
]

export const ORDER_CANCELLED = 'Cancelled'
export const ORDER_INPUT = 'Input'
export const ORDER_OUTPUT = 'Output'
export const ORDER_RUNNING = 'Running'
export const ORDER_PAUSED = 'Paused'
export const ORDER_STATE_COMPLETE = 'Complete'
export const TICKET_STATE_IN_PROGRESS = 'In Progress'
export const TICKET_STATE_COMPLETE = 'Complete'
export const INVALID_CONTAINER_STATUS = 'INVALID'
export const CASSETTE_BEACON_DOT_TYPE = 'cassette'
export const CRYOBATH_BEACON_DOT_TYPE = 'cryobath'
export const SCREEN_SAVER_SAFE_STATUS = 'safe'
export const SCREEN_SAVER_DANGER_STATUS = 'danger'
export const SCREEN_SAVER_WARNING_STATUS = 'warning'
export const typeEmbryo = {
  egg: 'Egg',
  replace: 'Oocyte'
}

export const whoPerformedThe = (procedure) => `Who performed the ${procedure}?`

// Ticket Procedures Reference

// EGG_FREEZE(1, “Egg Freeze”, SpecimenType.EGG, ProcedureType.FREEZE),
// EGG_THAW(2, “Egg Thaw”, SpecimenType.EGG, ProcedureType.THAW),
// EMBRYO_FREEZE(3, “Embryo Freeze”, SpecimenType.EMBRYO, ProcedureType.FREEZE),
// EMBRYO_THAW(4, “Embryo Thaw”, SpecimenType.EMBRYO, ProcedureType.THAW),
// IMPORT_CASSETTE(5, “Import Cassette”, null, ProcedureType.BULK_IMPORT),
// EGG_MOVE(6, “Egg Move”, SpecimenType.EGG, ProcedureType.MOVE),
// EMBRYO_MOVE(7, “Embryo Move”, SpecimenType.EMBRYO, ProcedureType.MOVE),
// EGG_REPLACE_BEACON(8, “Replace Beacon”, SpecimenType.EGG, ProcedureType.REPLACE_BEACON),
// EMBRYO_REPLACE_BEACON(9, “Replace Beacon”, SpecimenType.EMBRYO, ProcedureType.REPLACE_BEACON),
// EGG_BULK_IMPORT(10, “Egg Bulk Import”, SpecimenType.EGG, ProcedureType.BULK_IMPORT),
// EMBRYO_BULK_IMPORT(11, “Embryo Bulk Import”, SpecimenType.EMBRYO, ProcedureType.BULK_IMPORT);

export const embryoTypeValues = [
  {
    previousValue: 'Egg',
    newValue: 'Oocyte'
  },
  {
    previousValue: 'Beacon',
    newValue: 'Cryobeacon'
  }
]
export const MANUAL_HANDHELD_BEACON_SCAN = 'MANUAL_HANDHELD_BEACON_SCAN'
export const MANUAL_BEACON_ENTRY = 'MANUAL_BEACON_ENTRY'
export const MANUAL_MISSING_BEACON = 'MANUAL_MISSING_BEACON'
export const MANUAL_BEACON_REPLACE = 'MANUAL_BEACON_REPLACE'
export const ROBOT_BOX = 'ROBOT_BOX'
export const CRYO_BOX = 'CRYO_BOX'

export const GOOGLE_MAPS_KEY = 'AIzaSyAlf3HzU7pa9xOgZNAO46HIIkouCIyS7HI'

export const METHOD_NOT_SELECTED = 'Not_Selected'
export const METHOD_FREEZE = 'Freeze'
export const METHOD_FREEZE_LOWERCASE = 'freeze'
export const METHOD_FREEZE_UPPER = 'FREEZE'
export const METHOD_THAW = 'Thaw'
export const METHOD_IMPORT = 'Import'
export const METHOD_IMPORT_UPPER = 'IMPORT'
export const METHOD_IMPORT_LOWERCASE = 'import'
export const METHOD_MOVE = 'Move'
export const METHOD_EXPORT = 'Export'
export const METHOD_EXPORT_UPPER = 'EXPORT'
export const METHOD_EXPORT_LOWERCASE = 'export'
export const METHOD_DONATE = 'DONATION_TO_PATIENT'
export const METHOD_SEND = 'Send'
export const METHOD_SEND_UPPER = 'SEND'
export const METHOD_SEND_LOWERCASE = 'send'
export const METHOD_REQUEST = 'Request'
export const METHOD_REQUEST_UPPER = 'REQUEST'
export const METHOD_REQUEST_LOWERCASE = 'request'
export const PROCEDURE_EXPORT = 'Export Cryodevices'
export const PRINTER_READY = 'Ready'
export const PRINTER_NOT_READY = 'not_ready'
export const ZBP_READY = 'Ready'
export const ZBP_OFFLINE = 'Offline'
export const COMPLETED = 'COMPLETED'
export const FAILED = 'FAILED'
export const BEACON = 'Beacon'
export const CRYOBEACON = 'CryoBeacon'
export const CRYOBEACONS = 'CryoBeacons'
export const CRYOGRID = 'CryoGrid'
export const CRYOROBOT = 'CryoRobot'
export const CRYOROBOTS = 'CryoRobots'
export const RACK = 'Rack'
export const NUM_RACKS = 18
export const SHELF = 'Shelf'
export const SHELVES = ['A', 'B', 'C', 'D', 'E']
export const POSITION = 'Position'
export const CRYOROBOT_UPPERCASE = 'CRYOROBOT'
export const SOURCE = 'Source'
export const DESTINATION = 'Destination'
export const FET = 'FET'
export const CREATING_A = 'Creating a'
export const CREATING_AN = 'Creating an'
export const SPECIMEN = 'specimen'
export const SPECIMENS = 'specimens'
export const PATIENT_ID = 'Patient ID'
export const GLOBAL_PATIENT_NUMBER = 'globalPatientNumber'
export const PATIENT_NAME = 'Patient Name'
export const FILE_NAME = 'File Name'
export const CALENDAR_MODE_SINGLE = 'single'
export const CALENDAR_MODE_RANGE = 'range'
export const CALENDAR_MODE_NOW = 'now'
export const RESETED_PASSWORD_SUCCESSFULLY = 'Your password has been successfully changed'
export const UNASSIGNED = 'Unassigned'

export const LAYOUT_B3C = 1
export const LAYOUT_CRS = 2
export const LAYOUT_WAVE = 3
export const B3C = 'B3C'
export const CRS = 'CRS'
export const NON_TMRW = 'NON_TMRW'
export const LOCATION_TYPES = [B3C, CRS, NON_TMRW]

export const NON_TMRW_LOCATION = 'Non-TMRW Location'

export const PRIMARY_PATIENT = 'Primary Patient'
export const SELECTED_PATIENTS = 'Selected Patients'
export const REMOVE_PATIENT = 'Remove Patient'
// TICKETS TO PRINT
export const CLINIC_CRYOROBOT = 'Clinic Cryorobot'

// Export Reasons
export const TRANSFER_OUT = 'Transfer Out'
export const DISCARD = 'Discard'
export const DONATION = 'Donation'
export const OTHER = 'Other'
export const SHIPPING = 'Shipping'
export const HAND_CARRY = 'Hand Carry'
export const SHIPPING_ID = 'Shipping ID / Tracking #'
export const CARRIER_ID = 'Carrier ID'
export const NOTES = 'Notes'
export const DONATION_TO_RESEARCH = 'Donate to Research'
export const DONATE_TO_PATIENT = 'Donate to Patient'
export const DONATION_TO_PATIENT = 'Donation to Patient'
export const TRANSFER_OUT_VALUE = 'TRANSFER_OUT'
export const DISCARD_VALUE = 'DISCARD'
export const DONATION_VALUE = 'DONATION'
export const DONATION_UPPERCASE = 'DONATION'
export const OTHER_VALUE = 'OTHER'
export const SHIPPING_VALUE = 'SHIPPING'
export const HAND_CARRY_VALUE = 'HAND_CARRY'
export const DONATE_TO_RESEARCH_VALUE = 'DONATION_TO_RESEARCH'
export const DONATE_TO_PATIENT_VALUE = 'DONATION_TO_PATIENT'
export const PATIENTS = 'Patients'
export const PROCEDURE_TYPE_DONATION_UPPER = 'DONATION'
export const PROCEDURE_TYPE_RELOCATION_UPPER = 'RELOCATION'

// Login Message
export const WELCOME_MSG = 'Welcome to TMRW’s Specimen Management System.'
// scan beacon const
export const NEW_BEACON_STEP = 'newBeacon'
// add exisiting beacon constants
export const EXISTING_BEACON_SCREENING_STATUS_POSITIVE = 'SCREEN_POSITIVE'
export const EXISTING_BEACON_SCREENING_STATUS_NEGATIVE = 'SCREEN_NEGATIVE'
export const EXISTING_BEACON_SCREENING_STATUS_UNSCREENED = 'UNSCREENED'
export const EXISTING_BEACON_MANUAL_SCAN = 'MANUAL_SCAN'
export const EXISTING_BEACON_INVALID = 'INVALID'
export const EXISTING_BEACON_EMBRYO = 'Embryo'
export const EXISTING_BEACON_TICKET_SUCCESS_MESSAGE = 'Ticket updated successfully!'
export const CANCEL_TICKET_SUCCESS_MESSAGE = 'Ticket cancelled successfully!'
export const CANCEL_TICKET_ERROR_MESSAGE =
  'Ticket cancellation failed. Please check the ticket status try again.'
export const EXISTING_BEACON_STEP = 'existingBeacon'
export const EXISTING_BEACON_NEW_BEACON = 'newBeacon'

// biorepository
export const BIOREPOSITORY = 'Biorepository'
export const MOVE_LOCATION = 'Relocation'
export const CRYOSHIPPER = 'CryoShipper'
export const TICKET_RELOCATION = 'relocation'
export const TICKET_CRYOSHIPPER = 'cryoShipper'
export const MOVE_TICKET_ALREADY_EXISTS = 'A ticket already exists for this CryoBeacon'
export const BEACON_EXIST_ON_DESTINATION = 'Beacon in destination'
export const PROCEDURE_TYPE_BEACON_MOVE = 'BEACON_MOVE'
export const PROCEDURE_TYPE_CRYOSHIPPER = 'CRYOSHIPPER'
export const REMAINING_IN_CRYOBEACON = 'REMAINING IN CRYOBEACON'
export const TO_AND_FROM = 'TO_AND_FROM'
export const CRYOSHIPPER_VIEW_MODE = 'cryoshipper-created'

export const ADD_DETAILS = 'Add Details'
export const ADD_FREEZE_DETAILS = 'Add Freeze Details'
export const EDIT_FREEZE_DETAILS = 'Edit Freeze Details'
export const ADD_THAW_DETAILS = 'Add Thaw Details'
export const EDIT_THAW_DETAILS = 'Edit Thaw Details'
export const ADD_IMPORT_DETAILS = 'Add Import Details'
export const EDIT_IMPORT_DETAILS = 'Edit Import Details'
export const ADD_EXPORT_DETAILS = 'Add Export Details'
export const EDIT_EXPORT_DETAILS = 'Edit Export Details'

export const CUSTOM_SELECTION_PROCEDURES = [PROCEDURE_TYPE_BATCH, PROCEDURE_TYPE_CRYOSHIPPER]
export const CUSTOM_SELECTION_PROCEDURES_OPTIONS = {
  batch: {
    selectionIcon: 'fa fa-eye text-tmrw-blue-dark !text-lg',
    startsOnKey: ROBOT_LOCATIONS,
    headers: [
      {
        name: 'batch',
        colspan: 4,
        content: (value) => {
          const { children, robotTransfer, ticketId, childrenCount, robotLocations } = value
          const effectiveRobotTransfer = robotTransfer || robotLocations[0]?.transferType
          const ticketProcedure = effectiveRobotTransfer === 'IN' ? 'Store' : 'Retrieve'
          const childCount = children?.length || childrenCount || 0
          return `<span data-test="batch-description" class="border-b-1 pb-2 border-solid font-bold">Batch ${ticketProcedure} ticket - ${childCount} tickets included - #${ticketId}</span>`
        }
      },
      {
        name: 'batch-ticket-count',
        colspan: 1,
        content: (value) => {
          const { children, childrenCount, screeningStatus } = value
          const childCount = children?.length || childrenCount || 0
          const screenStatusImageList = [
            {
              id: 1,
              fileName: imagePositiveScreenStatus,
              altText: 'Positive Status Icon',
              class: 'screening-status-label__icon--positive'
            },
            {
              id: 2,
              fileName: imageNegativeScreenStatus,
              altText: 'Negative Status Icon',
              class: 'screening-status-label__icon--negative'
            },
            {
              id: 3,
              fileName: imageUnscreenedScreenStatus,
              altText: 'Unscreen Status Icon',
              class: 'screening-status-label__icon--unscreened'
            }
          ]
          const screeningStatusLabel = screenStatusImageList.filter(
            (label) => label.id === screeningStatus
          )[0]

          return screeningStatusLabel
            ? `<span data-test="batch-ticket-count">
               <img class="w-6 max-w-initial mr-1 inline svg-white ${screeningStatusLabel.class}" 
                    src="${screeningStatusLabel.fileName}" 
                    alt="${screeningStatusLabel.altText}" 
                    height="26"> 
               ${childCount} Tickets 
             </span>`
            : `<span></span>`
        }
      }
    ]
  },
  cryoshipper: {
    selectionIcon: 'custom-cryoshipper',
    startsOnKey: ROBOT_LOCATIONS,
    headers: [
      {
        name: 'cryoshipper-ticket-id',
        colspan: 2,
        content: (value) => {
          const { ticketId } = value
          return `<span data-test="cryoshipper-description" class="border-b-1 pb-2 border-solid font-bold">${CRYOSHIPPER} ticket  - #${ticketId}</span>`
        }
      },
      {
        name: 'cryoshipper-shipper-id',
        colspan: 2,
        content: (value) => {
          const {
            metadata: { cryoshipperLabel }
          } = value
          if (cryoshipperLabel?.length >= MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE)  {
            return `<span data-test="cryoshipper-description" class="border-b-1 pb-2 border-solid font-bold">${CRYOSHIPPER} ID <br/> ${cryoshipperLabel?.slice(
              0,
              MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE
            )}</span>${
              cryoshipperLabel.length > MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE ? '<br/>' : ''
            }
            <span data-test="cryoshipper-description" class="border-b-1 pb-2 border-solid font-bold">${cryoshipperLabel?.slice(
              MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE,
              MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 2
            )}</span>${
              cryoshipperLabel.length > MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 2 ? '<br/>' : ''
            }
            <span data-test="cryoshipper-description" class="border-b-1 pb-2 border-solid font-bold">${cryoshipperLabel?.slice(
              MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 2,
              MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 3
            )}</span>${
              cryoshipperLabel.length > MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 3 ? '<br/>' : ''
            }
            <span data-test="cryoshipper-description" class="border-b-1 pb-2 border-solid font-bold">${cryoshipperLabel?.slice(
              MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 3,
              MAX_CHARACTERS_IN_ASSOCIATED_ID_LINE * 4
            )}</span>
            `
          }else {
            return `<span data-test="cryoshipper-description" class="border-b-1 pb-2 border-solid font-bold">${CRYOSHIPPER} ID ${
              cryoshipperLabel || '------'
            }</span>`
          }
        } 
      },
      {
        name: 'cryoshipper-ticket-count',
        content: (value) => {
          const {
            metadata: { childrenCount }
          } = value
          const count = childrenCount || 0
          return `<span data-test="cryoshipper-description" class="border-b-1 pb-2 border-solid font-bold">${count} Tickets</span>`
        }
      }
    ]
  }
}

export const ROBOT_TRANSFER_OPTIONS = [
  {
    key: 'IN',
    value: 'Store'
  },
  {
    key: 'OUT',
    value: 'Retrieve'
  }
]

export const VIOS_FERTILITY_INSTITUTE_WEST_LOOP = 'VIOS Fertility Institute - West Loop'
export const EMPTY_CRYODEVICES = '0 Cryodevices'

export const USER_TYPES = {
  6: 'INSTITUTE',
  8: 'NONE'
}

export const REDESIGN_METHODS = {
  Freeze: 'Freezing',
  Import: 'Importing',
  Thaw: 'Thawing',
  Export: 'Exporting',
  [DONATE_TO_PATIENT_VALUE]: 'Exporting',
  [DONATE_TO_RESEARCH_VALUE]: 'Exporting'
}

// Title case procedure types for title labels
export const TITLE_CASE_PROCEDURE_TYPES = {
  FREEZE: 'Freeze',
  REPLACE_CRYOBEACON: 'Replace CryoBeacon',
  MOVE: 'Move',
  SINGLE_IMPORT: 'Single Import',
  THAW: 'Thaw',
  EXPORT: 'Export',
  MISSING_CRYOBEACON: 'Missing CryoBeacon',
  DONATION_TO_PATIENT: 'Donation To Patient'
}

export const CRYOLABEL_SCANNED_BY_USER = 'CRYOLABEL_SCANNED_BY_USER'

export const WHO_FREEZE_DATE_CANNOT_BE_IN_FUTURE = 'Date selected cannot be in the future.'
export const WHO_FREEZE_DATE_CANNOT_BE_BEFORE_TICKET_CREATION =
  'Date selected cannot be before the ticket was created.'

const RELOAD_OR_TRY_AGAIN_LATER = 'Reload the page or try again later.'
export const DYNAMIC_HEADER_ERROR = `An error occurred while loading the data. ${RELOAD_OR_TRY_AGAIN_LATER}`

export const ADDED_SHARED_PATIENT = 'Patient(s) successfully added to Cryodevice.'
export const ADD_SHARED_PATIENT_ERROR =
  'An unknown error occurred while adding a patient. Please try again.'
export const REMOVE_PATIENT_FROM_SPECIMEN_ERROR =
  'An unknown error occurred while removing patient. Please try again.'
export const REMOVED_PATIENT_FROM_SPECIMEN = 'Patient successfully removed from Cryodevice.'
export const COMPLETE_TICKET_ERROR = `Error completing ticket. ${RELOAD_OR_TRY_AGAIN_LATER}`
export const CANT_EDIT_PRIMARY_PATIENT = "Primary Patient can't be edited"
export const SEARCHING_PATIENTS = 'Searching Patients'
export const WHICH_PATIENT_WOULD_YOU_LIKE_TO_ADD = 'Which Patient(s) would you like to add?'
export const MAXIMUM_PATIENTS_ERROR = 'Maximum reached. You can only add up to 10 patients.'
export const PATIENT_IMPORT_ERROR = 'Error importing patient'
export const MAX_PATIENTS = 10
export const CHECKING_FOR_OPEN_TICKETS = 'Checking for open tickets'
export const NO_AVAILABLE_SPECIMENS_ERROR = (procedure) =>
  `This patients have no Specimens of this type stored.<br />A ${procedure} Ticket cannot be created.`
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS'
export const INVALID_CREDENTIALS_MESSAGE = 'Username or password incorrect'
export const INVALID_CREDENTIALS_MESSAGE_RAW = 'Incorrect User Name or Password'
export const UDI_BARCODE_NUMBER = '(01)00850031475045'

export const MAX_BATCH_LENGTH = 49

export const TICKET_MODULE_DEFAULT_STATE = () => ({
  ticketId: 0,
  state: '',
  substate: '',
  procedureName: '',
  procedureType: '',
  robotTransfer: null,
  procedureTime: 0,
  embryologistId: 0,
  patientDob: '',
  source: 1,
  inventoryUpdate: '',
  identificationNumber: '',
  patientName: '',
  procedure: '',
  robotLocations: [],
  children: [],
  specimens: [],
  statusImage: {
    sources: []
  },
  selected: false,
  parentId: 0,
  siteId: 0,
  transferId: '',
  performedBy: '',
  patientId: 0,
  metadata: {},
  globalPatientNumber: 0,
  createdAt: 0,
  type: '',
  userId: 0,
  cpId: 0,
  screeningStatus: 0,
  beaconBarcode: '',
  sourcesValue: {},
  beacons: [],
  cryoDevice: [],
  specimenAction: null,
  // TODO: this is store as an array when collecting the patient, but it is sent as an object, is this something we want to left
  patient: {
    firstName: '',
    lastName: '',
    identificationNumber: '',
    birthDate: ''
  },
  selectedPatients: [],
  eventId: 0,
  specimenType: '',
  visit: {
    procedureDateFrom: 0,
    procedureDateTo: null
  },
  selectedMethod: '', // TODO: move this to indermediate states
  selectedCryoDeviceType: {},
  selectedSpecimenType: null
})

// ROBOT LAYOUT TYPES MAP
export const ROBOT_LAYOUT_TYPES = [
  {
    id: 1,
    value: 'CryoRobot'
  },
  {
    id: 2,
    value: 'CryoRobot Select'
  },
  {
    id: 3,
    value: 'Non-TMRW Location'
  }
]

// Mass Create Messages
export const NO_RESULTS_MSG =
  'No results. Apply different search criteria. <br/>Up to 98 CryoBeacons can be processed at a time.'
export const ADD_CRITERIA_MSG =
  'Add Search Criteria to select CryoBeacons to add to the Relocation. <br/>Up to 98 CryoBeacons can be processed at a time.'
export const INCLUDE = 'Include'
export const INCLUDE_LOWER = 'include'
export const EXCLUDE = 'Exclude'
export const EXCLUDE_LOWER = 'exclude'

export const TOKEN_EXPIRATION_IN_MINUTES = 7
export const IDLE_TIMEOUT = 3
export const MODAL_SIGNOUT_TIMEOUT = 2
