<template>
  <input
    :ref="id"
    type="text"
    :placeholder="placeholder"
    v-model="autocompleteText"
    class="border-b border-dotted border-tmrw-blue bg-transparent leading-tight outline-none"
  />
</template>

<script>
import { Loader } from 'google-maps'
import { GOOGLE_MAPS_KEY } from '@/constants'

const options = {
  libraries: ['places']
}
const loader = new Loader(GOOGLE_MAPS_KEY, options)

export default {
  name: 'google-autocomplete',
  emits: ['setClinicName', 'selectedPlace'],
  props: {
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true,
      maxlength: {
        type: Number,
        default: 255
      }
    },
    placeType: {
      type: String,
      required: true
    },
    address: {
      type: [String, Object]
    },
    clinicName: {
      type: Object
    }
  },
  data() {
    return {
      google: null,
      /**
       * The Autocomplete object.
       *
       * @type {Autocomplete}
       * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
       */
      autocomplete: null,

      /**
       * Autocomplete input text
       * @type {String}
       */
      autocompleteText: '',

      /**
       * setAddress property watcher
       * @type {String/Object}
       */
      setAddress: this.address
    }
  },
  async mounted() {
    this.google = await loader.load()
    // eslint-disable-next-line no-undef
    this.autocomplete = new this.google.maps.places.Autocomplete(this.$refs[this.id], {
      types: [this.placeType]
    })
    this.autocomplete.addListener('place_changed', this.onPlaceChanged)
  },
  updated() {
    if (this.placeType === 'address') {
      this.$emit('setClinicName', this.autocompleteText)
    }
  },
  methods: {
    /**
     * When searching for an establishment, trigger Google Autocomplete
     */
    onPlaceChanged() {
      const place = this.autocomplete.getPlace()
      this.formatResult(place)
      // update autocompleteText then emit change event
      this.autocompleteText = this.placeType === 'address' ? place.formatted_address : place.name
    },

    /**
     * Format result from Geo google APIs
     * @param place
     * @returns {{formatted output}}
     */
    formatResult(place) {
      const formattedData = {}
      const ADDRESS_COMPONENTS = [
        'name',
        'street_number',
        'route',
        'locality',
        'sublocality',
        'administrative_area_level_1',
        'administrative_area_level_2',
        'country',
        'postal_code',
        'postal_town'
      ]

      for (let i = 0; i < ADDRESS_COMPONENTS.length; i++) {
        for (let ii = 0; ii < place.address_components.length; ii++) {
          for (let iii = 0; iii < place.address_components[ii].types.length; iii++) {
            if (place.address_components[ii].types[iii] === ADDRESS_COMPONENTS[i]) {
              formattedData[ADDRESS_COMPONENTS[i]] = place.address_components[ii].long_name
            }
          }
        }
      }

      const establishmentData = {
        name: place.name,
        country: formattedData['country'],
        city:
          formattedData['locality'] || formattedData['sublocality'] || formattedData['postal_town'],
        streetAddress: formattedData['street_number']
          ? `${formattedData['street_number']} ${formattedData['route']}`
          : place.formatted_address,
        state: formattedData['administrative_area_level_1'],
        zip: formattedData['postal_code']
      }
      this.$emit('selectedPlace', establishmentData)
      return establishmentData
    }
  },
  watch: {
    address(clinicData) {
      if (clinicData) {
        this.autocompleteText = clinicData.streetAddress
      }
    },
    clinicName(clinicData) {
      if (clinicData) {
        this.autocompleteText = clinicData.name
      }
    }
  }
}
</script>
