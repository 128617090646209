<template>
  <vue-final-modal
    data-test="modal-bulk"
    class="w-full m-auto text-tmrw-blue"
    @closed="clickedModalOut"
    :clickToClose="false"
    name="modal-bulk"
    height="auto"
    :modelValue="show"
    classes="modal--container"
    content-class="modal--content"
    :content-style="{ height: 'auto', width: '700px' }"
  >
    <section class="w-11/12 m-auto text-tmrw-blue pb-6">
      <div class="flex flex-row mt-4">
        <ActionBar data-test="modal-bulk__action-bar" colsDistribution="9/3">
          <template v-slot:left-side>
            <dynamic-title titleType="h3-blue" floatTo="left">
              {{ modalTitle }}
            </dynamic-title>
          </template>
          <template v-slot:right-side>
            <i
              @click="handleClose"
              class="
                w-2/12
                flex
                items-center
                justify-end
                far
                fa-times-circle
                cursor-pointer
              "
              :class="{ 'fas--disabled': processingUpload }"
            />
          </template>
        </ActionBar>
      </div>
      <div class="flex flex-row mt-6 mb-4">
        <p class="w-8/12 flex justify-start">
          {{ bulkImportType === "patients" ? "Patient" : "Specimen" }} record must be a
          .csv file
        </p>
        <div class="flex w-4/12 justify-end">
          <i class="fas fa-file-download mr-4" />
          <a
            v-if="bulkImportType === 'patients'"
            data-test="patients-template-download-link"
            :href="patientsTemplateDownloadLink"
            target="_blank"
          >Download Template</a>
        </div>
      </div>
      <loading-ui
        v-if="processingUpload"
        class="mb-4"
        modal
        :message="PROCESSING_PATIENT_BULK_IMPORT"
      />
      <div v-if="isJobStatusFailed">
        <div
          class="
            bg-tmrw-warning
            rounded
            p-4
            text-white
            flex flex-col
            items-start
          "
        >
          <p class="font-bold">
            The file you uploaded contained errors and was not imported.
          </p>
          <p class="my-4 leading-5">
            We've updated the file to show which entries are in error. Click
            download to see the updated file, and to correct the affected
            entries.
          </p>
          <div
            class="
              flex flex-row
              bg-white
              p-2
              border-solid border-1 border-tmrw-blue
              cursor-pointer
              text-tmrw-blue
              rounded
              w-auto
            "
            v-on:click="handleDownloadFile"
          >
            <i class="fas fa-download mr-2" />
            <p class="font-bold">Download</p>
          </div>
        </div>
        <p class="mt-6 mb-4">Or upload a new file:</p>
      </div>

      <div
        v-if="file && file?.name"
        class="
          flex
          items-center
          justify-between
          border-solid border-1 border-tmrw-blue-light
          rounded
          p-4
          text-tmrw-blue-light
          mb-4
        "
      >
        <p>{{ file?.name }}</p>
        <i
          class="
            w-8
            flex
            items-center
            justify-end
            far
            fa-times-circle
            cursor-pointer
          "
          @click="removeFile"
        />
      </div>
      <div
        class="
          border-dashed border-2 border-tmrw-blue-light
          rounded
          bg-tmrw-green-light
          text-tmrw-blue-light
          relative
          text-center
          h-56
        "
        v-if="!file && !file?.name"
      >
        <i class="fas fa-cloud-upload-alt mt-12 mb-2 text-2xl" />
        <p class="mb-10">Drag and drop to upload</p>
        <p class="underline text-tmrw-blue font-bold">
          Or click to choose a file
        </p>
        <p class="mt-2 text-tmrw-error" v-if="error">{{ error }}</p>
        <form id="csv-file-upload-form" enctype="multipart/form-data">
          <input
            class="
              absolute
              cursor-pointer
              opacity-0
              h-full
              w-full
              flex flex-col
              inset-0
            "
            type="file"
            id="file"
            ref="file"
            v-on:change="handleFileUpload"
          />
        </form>
        <p class="mt-4 text-tmrw-error" v-if="fileError">
          Something went wrong while uploading your file. Please try again.
        </p>
      </div>
      <p
        class="mt-4 text-tmrw-success font-bold"
        v-if="generalJobStatus && generalJobStatus.status === 'COMPLETED'"
      >
        The file you uploaded was successfully imported.
      </p>
      <div>
        <button-dynamic
          btnText="Import"
          btnType="button"
          floatTo="right"
          showIcon
          btnStyle="primary"
          :isDisabled="!file"
          fontAwesomeIconClass="cloud-upload-alt"
          addMarginTop
          addMarginBottom
          @click="processBulkImportFile"
        />
        <button-dynamic
          btnText="Cancel"
          btnType="button"
          floatTo="right"
          btnStyle="transparent-blue"
          :isDisabled="processingUpload"
          addMarginTop
          addMarginBottom
          addMarginRight
          @click="handleClose"
        />
      </div>
    </section>
  </vue-final-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import toast from '@/config/toast'
import {
  COMPLETED,
  FAILED,
  WRONG_MESSAGE_PATIENT_BULK,
  ERROR_MESSAGE_FILE_CSV,
  PROCESSING_PATIENT_BULK_IMPORT
} from '@/constants'

export default {
  components: {
    LoadingUi,
    ButtonDynamic,
    DynamicTitle,
    ActionBar
  },
  name: 'modal-bulk',
  data() {
    return {
      error: '',
      file: '',
      filename: '',
      fileError: false,
      processingUpload: false,
      generalJobStatus: {},
      intervalPatient: null,
      PROCESSING_PATIENT_BULK_IMPORT
    }
  },
  emits: ['closeModal'],
  props: {
    bulkImportType: {
      type: String,
      default: 'patients',
      required: false,
      validator: (value) => [
        'patients' // Set Modal to import Patients
      ].includes(value)
    },
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    closeModal: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters('patientsModule', ['jobStatus', 'patientTimestamp']),
    modalTitle() {
      return 'Import Patient Record'
    },
    patientsTemplateDownloadLink() {
      return 'https://tmrw.gitlab.io/eng/app-config/patient_template.csv'
    },
    isJobStatusFailed() {
      return (
        this.generalJobStatus
        && this.generalJobStatus.status === 'FAILED'
        && !this.processingUpload
      )
    }
  },
  methods: {
    ...mapActions('patientsModule', [
      'fetchPatients',
      'setJobStatusDefault',
      'getPatientsUploadStatus',
      'postUpload',
      'checkJobStatus',
      'setPatientTimestamp'
    ]),
    processBulkImportFile() {
      const formData = new FormData()
      formData.append('file', this.file)
      this.processingUpload = true
      if (this.bulkImportType === 'patients') {
        this.postUploadPatient(formData)
      }
    },
    removeFile() {
      this.file = ''
    },
    handleBulkStatus(response) {
      const showError = () => {
        this.stopLoader()
        this.handleReset()
        toast.error({ title: WRONG_MESSAGE_PATIENT_BULK })
      }

      if (!response) {
        showError()
        return
      }
      const { status } = response

      switch (status) {
        case COMPLETED:
          this.stopLoader()
          this.handleReset()
          break

        case FAILED:
          showError()
          break

        default:
          break
      }
    },
    stopLoader() {
      this.clearInterval()
      this.setPatientTimestamp(null)
      this.fetchPatients()
      this.processingUpload = false
    },
    async postUploadPatient(formData) {
      try {
        const postUpload = await this.postUpload(formData)
        const id = postUpload
        this.intervalPatient = setInterval(async () => {
          const checkJobStatus = await this.checkJobStatus(id)
          this.handleBulkStatus(checkJobStatus)
        }, 5000)
      } catch (error) {
        this.stopLoader()
        this.file = ''
        this.filename = ''
        this.fileError = true
      }

      return this.intervalPatient
    },
    clearInterval() {
      clearInterval(this.intervalPatient)
      this.intervalPatient = null
    },
    handleFileUpload() {
      this.error = ''
      this.fileError = false
      const file = this.$refs.file.files[0]
      if (
        file
        && file?.name
        && file.name.substr(file.name.length - 4, 4) !== '.csv'
      ) {
        this.error = ERROR_MESSAGE_FILE_CSV
        return
      }
      if (file && file?.name) {
        this.filename = file.name
      }
      this.file = file
    },
    handleDownloadFile() {
      const { jobId, status } = this.generalJobStatus
      const { filename } = this
      if (this.bulkImportType === 'patients') {
        this.getPatientsUploadStatus({ jobId, status, filename })
      }
    },
    handleClose() {
      this.generalJobStatus = {}
      this.fileError = false
      if (!this.processingUpload) {
        this.file = ''
        this.error = ''
        this.setJobStatusDefault()
      }
      this.$emit('closeModal')
    },
    clickedModalOut() {
      this.handleClose()
    },
    handleReset() {
      this.file = ''
      this.error = ''
    }
  },
  watch: {
    jobStatus(newValue) {
      if (newValue && newValue.status) {
        this.generalJobStatus = newValue
      }
    }
  }
}
</script>
<style lang="scss">
.modal {
  &--content {
    // default settings of vue-js-modal
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);

    display: flex;
    flex-direction: column;
    //margin: 0 1rem;
    //padding: 1rem;
    //border: 1px solid #e2e8f0;
    //border-radius: 0.25rem;
    //background: #fff;
  }
  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
